/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/19/2020
 * @Example 
 */

import React                         from "react";
import PropTypes                     from "prop-types";
import Config                        from "../../../../config";
import {Typography, withStyles}      from "@material-ui/core";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W25F2011Actions          from "../../../../redux/W2X/W25F2011/W25F2011_actions";
import Divider                       from "@material-ui/core/Divider";
import {Checkbox}                    from "../../../common/form-material";

const styles = theme => ({
    item:    {
        padding: "15px 0"
    },
    heading: {
        fontSize:      12,
        fontWeight:    'bold',
        textTransform: "uppercase",
        transform:     "translateY(1px)",
        padding: "10px 0"
    },
    success: {
        color: theme.palette.success.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    danger:  {
        color: theme.palette.danger.main,
    },
});

const listStatus = [
    {
        ID: "PUBLIC",
        Name: "DHR_Dang_cong_khai",
        Description: "DHR_Dang_cong_khai_W25DESC",
        Color: "success",
    },
    {
        ID: "INTERNAL",
        Name: "DHR_Dang_noi_bo",
        Description: "DHR_Dang_noi_bo_W25DESC",
        Color: "warning",
    },
    {
        ID: "DRAFT",
        Name: "DHR_Nhap_",
        Description: "DHR_Nhap_W25DESC",
        Color: "",
    },
    {
        ID: "CLOSE",
        Name: "DHR_Dong1",
        Description: "DHR_Dong_W25DESC",
        Color: "danger",
    },
];
class W25F2011Status extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            StatusID: "",
            loading: false,
        };
        this.timer = null;
    }

    loadForm = () => {
        const { loadForm } = this.props;
        if (loadForm) {
            loadForm(4, (error, data) => {
                this.setState({
                    StatusID: data && data.StatusID ? data.StatusID : ""
                })
            });
        }
    };

    componentDidMount () {
        this.loadForm();
    }

    handleChange = (e) => {
        if (e && e.target.value) {
            if (this.timer) clearTimeout(this.timer);
            this.setState({StatusID: e.target.value}, () => {
                this.timer = setTimeout(() => {
                    this._saveData();
                }, 1000);
            });
        }
    };

    _saveData = () => {
        const {data} = this.props;
        const {StatusID} = this.state;
        const params = {
            RecInfoID: data && data.RecInfoID ? data.RecInfoID : "",
            StatusID: StatusID
        };
        // this.setState({loading: true});
        this.props.W25F2011Actions.saveStatus(params, (error, data) => {
            // this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.Status === 1) {
                Config.popup.show("INFO", data.Message);
                return false;
            }
        });

    };

    render() {
        const {classes, disabled} = this.props;
        const {loading, StatusID} = this.state;

        return (
            <div style={{width: '100%'}}>
                {listStatus && listStatus.map((status, idx) => {
                    return (
                        <div key={idx} className={classes.item}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{verticalAlign: "top"}}>
                                            <Checkbox
                                                checked={StatusID === status.ID}
                                                value={status.ID}
                                                size={"medium"}
                                                label={""}
                                                disabled={loading || disabled}
                                                color={"primary"}
                                                onChange={this.handleChange}
                                            />
                                        </td>
                                        <td>
                                            <Typography className={classes.heading + " " + (classes[status.Color] ? classes[status.Color] : "")}>{Config.lang(status.Name)}</Typography>
                                            <Typography style={{marginBottom: 10}} className={"text-muted mgb10"} variant={"body2"}>{Config.lang(status.Description)}</Typography>
                                            {idx < listStatus.length - 1 && <Divider />}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
        );
    }

}

W25F2011Status.propTypes = {
    data: PropTypes.object,

    loadForm: PropTypes.func
};

export default compose(connect(null,
    (dispatch) => ({
        W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
    }), null, {forwardRef: true}
), withStyles(styles, {withTheme: true}))(W25F2011Status);
