/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/19/2020
 * @Example 
 */

import React                                   from "react";
import PropTypes                               from "prop-types";
import {FormGroup, Image}                      from "react-bootstrap";
import Config                                  from "../../../../config";
import {Typography, withStyles}                from "@material-ui/core";
import IconButton                              from "@material-ui/core/IconButton";
import Icons                                   from "../../../common/icons";
import ButtonGeneral                           from "../../../common/button/button-general";
import {bindActionCreators, compose}           from "redux";
import {connect}                               from "react-redux";
import * as W25F2011Actions                    from "../../../../redux/W2X/W25F2011/W25F2011_actions";
import Divider                                 from "@material-ui/core/Divider";
import W25F2011AddStage                        from "./tab3/W25F2011AddStage";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Checkbox, Combo}                       from "../../../common/form-material";

const styles = theme => ({
    item:    {
        padding: " 24px 0"
    },
    heading: {
        fontSize:      12,
        fontWeight:    'bold',
        textTransform: "uppercase",
        transform:     "translateY(1px)",
        marginBottom: 10
    },
    info: {
        color: theme.palette.info.main,
    },
    success: {
        color: theme.palette.success.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    danger:  {
        color: theme.palette.danger.main,
    },
    link: {
        color: theme.palette.text.subtext,
        "&:hover": {
            color: theme.palette.info.main
        }
    }
});

let dataCboMailTemplate = [];
let onToggleStages = null;
let deleteStage = null;
let changeComboMailTemplate = null;
let onChangeOrder = null;
let onChangeApprovalOffer = null;
let _disabled = 0;
class W25F2011WorkFlow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            showAddStages: false,
            rowData: null,
            mode: "add",
            listWorkFlow: [
                {
                    ID: "CURRENT",
                    Name: "DHR_Giai_doan_hien_co",
                    Color: "info",
                    stages: []
                },
                {
                    ID: "QUALIFIED",
                    Name: "Qualified",
                    Color: "success",
                    stages: []
                },
                {
                    ID: "REJECTED",
                    Name: "Rejected",
                    Color: "danger",
                    stages: []
                },
            ]
        };
        this.filter = {};
        this.renderAddStages = false;
        this.changeOrderTimer = null;
        this.changeApprovalTimer = null;
    }

    loadForm = () => {
        const { loadForm } = this.props;
        const {listWorkFlow} = this.state;
        if (loadForm) {
            loadForm(2, (error, data) => {
                if (data) {
                    const _listWorkFlow = listWorkFlow.map((workflow) => {
                        workflow.stages = data.filter(d => d.GroupType === workflow.ID);
                        return workflow;
                    });
                    this.setState({
                        dataGrid: data ? data : [],
                        listWorkFlow: _listWorkFlow
                    })
                }
            });
        }
    };

    loadCboMailTemplate = () => {
        this.setState({loading: true});
        this.props.W25F2011Actions.getCboMailTemplate((error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    componentDidMount () {
        this.loadCboMailTemplate();
        this.loadForm();
    }

    toggleStages = (mode, data = null, isUpdate) => {
        this.renderAddStages = true;
        this.setState({
            showAddStages: !!mode,
            mode: mode ? mode : "",
            rowData: data
        }, () => {
            if (isUpdate) {
                this.loadForm();
            }
        });
    };

    changeComboMailTemplate = (e, value) => {
        if (!e || !value) return false;
        const {RecInfoID} = this.props.data;
        const {StageID, StageName, IsAutoSendmail, GroupType} = e;
        const {listWorkFlow} = this.state;
        const workflows = listWorkFlow.map(wf => {
            wf.stages = wf.stages.map(stage => {
                if (stage.StageID === StageID) {
                    stage.TemplateMailID = value
                }
                return stage;
            });
            return wf;
        });
        this.setState({
            listWorkFlow: workflows
        }, () => {
            let params                                            = {
                RecInfoID:      RecInfoID || "",
                FormID:         "W25F2011",
                StageID:        StageID ? StageID : "",
                StageName:      StageName ? StageName : "",
                IsAutoSendmail: IsAutoSendmail ? Number(IsAutoSendmail) : 0,
                TemplateMailID: value ? value : "",
                IsStageSystem:  GroupType && GroupType !== "CURRENT" ? 1 : 0
            };
            this.setState({loading: true});
            this.props.W25F2011Actions.updateStages(params, (error) => {
                this.setState({loading: false});
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
            });
        });
    };

    deleteStages = (data) => {
        const {data: dataForm} = this.props;

        const params = {
            FormID: "W25F2011",
            FieldName: "W25F2011",
            RecInfoID: dataForm && dataForm.RecInfoID ? dataForm.RecInfoID : "",
            Mode: 2,
            Language: Config.language || "84",
            StageID: data && data.StageID ? data.StageID : ""
        };
        Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_muon_xoa?"),
            () => {
                this.setState({loading: true});
                this.props.W25F2011Actions.deleteStages(params, (error, data) => {
                    this.setState({loading: false});
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    }
                    if (data) {
                        if (data.Status === 1) {
                            Config.popup.show("INFO", data.Message);
                        } else {
                            Config.notify.show("success", Config.lang("DHR_Xoa_thanh_cong"), 3000);
                            this.loadForm();
                        }
                    }
                });
            });
    };

    renderButtonAction = e => {
        const {data} = e;
        return (
            <div className="">
                <IconButton
                    aria-label="view"
                    style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
                    onClick={() => this.toggleCriteria("edit", data)}
                >
                    <Icons name={"edit"} />
                </IconButton>
                <IconButton
                    aria-label="delete"
                    style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                    onClick={() => this.deleteCriteria(e)}
                >
                    <Icons name={"delete"} />
                </IconButton>
            </div>
        );
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = (result, GroupID, startIndx, endIndx) => {
        // dropped outside the list
        if ((result && !result.destination) || !GroupID) {
            return;
        }
        if (result) {
            startIndx = result.source.index;
            endIndx = result.destination.index;
        }
        if (startIndx !== endIndx) {
            const {listWorkFlow} = this.state;
            const workflows      = listWorkFlow.map(wf => {
                if (wf.ID === GroupID) {
                    if (startIndx > -1 && endIndx < wf.stages.length) {
                        wf.stages = this.reorder(
                            wf.stages,
                            startIndx,
                            endIndx
                        );
                    }
                }
                return wf;
            });

            this.setState({
                listWorkFlow: workflows
            }, () => {
                if (this.changeOrderTimer) clearTimeout(this.changeOrderTimer);
                this.changeOrderTimer = setTimeout(() => {
                    this._saveChangedOrder();
                }, 1000);
            });
        }
    };

    _saveChangedOrder = () => {
        const {RecInfoID} = this.props.data;
        const {listWorkFlow} = this.state;
        let data = [];
        listWorkFlow.forEach(workflow => {
            if (workflow.stages) data = data.concat(workflow.stages);
        });
        const params = {
            RecInfoID: RecInfoID,
            data: JSON.stringify(data)
        };
        this.setState({loading: true});
        this.props.W25F2011Actions.updateReorderStages(params, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            // if (data) {
            //     if (data.Status === 1) {
            //         Config.popup.show("INFO", data.Message);
            //         return false;
            //     } else {
            //         this.loadForm();
            //     }
            // }
        });
    };

    onChangeApprovalOffer = (e) => {
        const {RecInfoID} = this.props.data;
        const isChecked = e.target.checked ? 1 : 0;

        const {listWorkFlow} = this.state;
        const workflows = listWorkFlow.map(wf => {
            wf.stages = wf.stages.map(stage => {
                if (stage.StageID === "OFFERED") {
                    stage.IsApprovalOffer = isChecked
                }
                return stage;
            });
            return wf;
        });
        this.setState({
            listWorkFlow: workflows
        }, () => {
            const params = {
                RecInfoID: RecInfoID,
                IsApprovalOffer: isChecked
            };
            if (this.changeApprovalTimer) clearTimeout(this.changeApprovalTimer);
            this.changeApprovalTimer = setTimeout(() => {
                this.setState({loading: true});
                this.props.W25F2011Actions.updateApprovalOffer(params, (error, data) => {
                    this.setState({loading: false});
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    }
                    if (data && data.Status === 1) {
                        Config.popup.show("INFO", data.Message);
                        return false;
                    }
                });
            }, 1000);
        });
    };

    render() {
        const {classes, data, getCboMailTemplate, disabled} = this.props;
        const { rowData, mode, showAddStages, listWorkFlow } = this.state;
        let count = 1;
        dataCboMailTemplate = getCboMailTemplate;
        onToggleStages = this.toggleStages;
        deleteStage = this.deleteStages;
        changeComboMailTemplate = this.changeComboMailTemplate;
        onChangeOrder = this.onDragEnd;
        onChangeApprovalOffer = this.onChangeApprovalOffer;
        _disabled = disabled;

        return (
            <div style={{width: '100%'}}>
                {(showAddStages || this.renderAddStages) && <W25F2011AddStage
                    mode={mode}
                    rowData={rowData}
                    dataForm={data}
                    open={showAddStages}
                    onClose={this.toggleStages}
                />}
                <FormGroup>
                    {listWorkFlow && listWorkFlow.map((workflow, idx) => {
                        const stages = workflow.stages;
                        const _count = count;
                        count += stages.length;
                        return (
                            <div key={idx} className={classes.item}>
                                <Typography className={classes.heading + " " + (classes[workflow.Color] ? classes[workflow.Color] : "")}>{Config.lang(workflow.Name)}</Typography>

                                {workflow.ID === "CURRENT" && !_disabled ? (
                                    <>
                                        <W25F2011ItemDragStage
                                            stages={stages}
                                            type={workflow.ID}
                                            classes={classes}
                                            count={_count}
                                            onDragEnd={(e) => this.onDragEnd(e, workflow.ID)}
                                        />
                                        <div style={{marginTop: 15}}>
                                            <ButtonGeneral
                                                name={Config.lang("DHR_Them_giai_doan")}
                                                typeButton={"add"}
                                                variant={"text"}
                                                style={{ textTransform: "uppercase" }}
                                                size={"large"}
                                                disabled={disabled}
                                                onClick={() => this.toggleStages("add")}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <W25F2011ItemStage stages={stages} classes={classes} count={_count}/>
                                )}
                                {idx < listWorkFlow.length - 1 && <Divider style={{marginTop: 20, backgroundColor: "#9597A1"}} />}
                            </div>
                        );
                    })}
                </FormGroup>
            </div>
        );
    }

}

class W25F2011ItemStage extends React.Component {

    render () {
        const {stages, count} = this.props;
        const icons = {
            OFFERED: require("../../../../assets/images/offered.svg"),
            HIRED: require("../../../../assets/images/hired.svg"),
            REJECTED: require("../../../../assets/images/rejected.svg")
        };
        return (
            <div>
                <table style={{width: "100%"}}>
                    <tbody>
                    {stages && stages.map((stage, idx) => {
                        const _count = count + idx;
                        return (
                            <W25F2011Item
                                key={idx}
                                data={stage}
                                icon={icons[stage.StageID.toUpperCase()]}
                                allowEdit={false}
                                count={_count}
                            />
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}

class W25F2011ItemDragStage extends React.Component {

    render () {
        const {classes, stages, type, count, onDragEnd} = this.props;
        return (
            <DragDropContext
                onDragEnd={onDragEnd && onDragEnd}
            >
                <table style={{width: "100%"}}>
                    <Droppable droppableId={type}
                               type={'done'}
                    >
                        {(provided, snapshot) => {
                            return (
                                <tbody
                                     ref={provided.innerRef}
                                     style={{minHeight: "600px"}}
                                     // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                                     {...provided.droppableProps}
                                >
                                    {stages && stages.map((stage, idx) => {
                                        const _count = count + idx;
                                        return (
                                            <Draggable key={stage.StageID} draggableId={stage.StageID} index={idx}>
                                                {(provided1, snapshot1) => {
                                                    const style = {
                                                        // backgroundColor: snapshot1.isDragging ? 'blue' : 'white',
                                                        width: "100%",
                                                        ...provided1.draggableProps.style,
                                                    };
                                                    return (
                                                        <W25F2011Item
                                                            data={stage}
                                                            allowEdit={true}
                                                            count={_count}
                                                            index={idx}
                                                            style={style}
                                                            classes={classes}
                                                            innerRef={provided1.innerRef}
                                                            draggableProps={provided1.draggableProps}
                                                            dragHandleProps={provided1.dragHandleProps}
                                                        />
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </tbody>
                            )
                        }}
                    </Droppable>
                </table>
            </DragDropContext>
        );
    }
}

class W25F2011Item extends React.Component {

    onEdit = (data) => {
        if (onToggleStages) {
            onToggleStages("edit", data);
        }
    };

    onDelete = (data) => {
        if (deleteStage) deleteStage(data);
    };

    render () {
        const {data, index,icon, allowEdit, count, classes, style, innerRef, draggableProps, dragHandleProps} = this.props;

        return (
            <tr {...draggableProps}
                {...dragHandleProps}
                className="pdt10 pdb10"
                ref={innerRef}
                style={style}
            >
                <td style={{
                    width:     50,
                    textAlign: "right"
                }}>
                    {data && data.GroupType && data.GroupType === "CURRENT" ?
                    <div className={"display_col align-center"}>
                        <IconButton size={"small"} style={{borderRadius: 5, padding: "1px 8px"}}
                                    disabled={_disabled}
                                    onClick={() => onChangeOrder && onChangeOrder(null, data.GroupType, index, index-1)}>
                            <Icons src={require("./icons/icon_up.svg")} width={20} height={20}/>
                        </IconButton>
                        <IconButton size={"small"} style={{borderRadius: 5, padding: "1px 8px"}}
                                    disabled={_disabled}
                                    onClick={() => onChangeOrder && onChangeOrder(null, data.GroupType, index, index+1)}>
                            <Icons src={require("./icons/icon_down.svg")} width={20} height={20}/>
                        </IconButton>
                    </div>
                        :
                    <Image src={icon ? icon : require("../../../../assets/images/mode.svg")}/>
                    }
                </td>
                <td style={{textAlign: "center"}}>
                    <Divider orientation="vertical" style={{
                        width:  2,
                        height: 40,
                        margin: '0 15px 0 6px'
                    }} component={"div"}/>
                </td>
                <td style={{width: 150}}>
                    <Typography style={{marginBottom: 10}}
                                className={"text-muted"} variant={"body2"}>
                        {Config.lang("DHR_Buoc") + " " + count}
                    </Typography>
                    <span style={{fontWeight: "bold"}}>{data.StageName || ""}</span>
                </td>
                <td style={{minWidth: 480}}>
                    <Combo
                        // error={error && error['RecPositionID']}
                        dataSource={Config.storeDataSoureDevExtreme(dataCboMailTemplate)}
                        displayExpr={'TemplateMailName'}
                        valueExpr={'TemplateMailID'}
                        margin={"normal"}
                        stylingMode={'underlined'}
                        disabled={!!data.IsAutoSendmail || _disabled}
                        value={data.TemplateMailID || ""}
                        shrink={true}
                        label={Config.lang('DHR_Mau_email_hoac_phong_van')}
                        onValueChanged={(e) => changeComboMailTemplate && changeComboMailTemplate(data, e.value)}
                    />
                    {data.StageID === "OFFERED" && <Checkbox
                        checked={!!data.IsApprovalOffer}
                        size={"medium"}
                        label={Config.lang("DHR_Xet_duyet_Offer")}
                        disabled={_disabled}
                        controlProps={{
                            inputProps: {
                                readOnly: true
                            }
                        }}
                        style={{marginLeft: -12}}
                        color={"primary"}
                        onChange={(e) => onChangeApprovalOffer(e)}
                    />}
                </td>
                <td style={{
                    width:     100,
                    textAlign: "center"
                }}>
                    {allowEdit && <div className={"display_row align-center valign-middle"}>
                        {/* eslint-disable */}
                        <a className={!_disabled ? classes.link : ""} onClick={() => !_disabled ? this.onEdit(data) : null}>{Config.lang("DHR_Sua")}</a>
                        <Divider orientation="vertical" style={{
                            width:  2,
                            height: 11,
                            margin: '0 5px'
                        }}/>
                        <a className={!_disabled ? classes.link : ""} onClick={() => !_disabled ? this.onDelete(data) : null}>{Config.lang("DHR_Xoa")}</a>
                        {/* eslint-enable */}
                    </div>}
                </td>
            </tr>
        );
    }
}

W25F2011WorkFlow.propTypes = {
    data: PropTypes.object,

    loadForm: PropTypes.func,
    onChange: PropTypes.func
};

export default compose(connect(state => ({
        getCboMailTemplate: state.W25F2011.getCboMailTemplate,
        iPermission: state.general.iPermission,
    }),
    (dispatch) => ({
        W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
    }), null, {forwardRef: true}
), withStyles(styles, {withTheme: true}))(W25F2011WorkFlow);
