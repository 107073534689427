/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/16/2020
 * @Example
 */
import React                         from "react";
import PropTypes                     from "prop-types";
import {FormGroup}                   from "react-bootstrap";
import Config                        from "../../../../config";
import * as _                        from "lodash";
import {MForm}                       from "../../../common/form-material";
import ExpansionPanel                from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary         from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails         from "@material-ui/core/ExpansionPanelDetails";
import Typography                    from "@material-ui/core/Typography";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W25F2011Actions         from "../../../../redux/W2X/W25F2011/W25F2011_actions";
import * as generalActions          from "../../../../redux/general/general_actions";
import withStyles                   from "@material-ui/core/styles/withStyles";
import Icons                        from "../../../common/icons";
import W25F2011Expand1              from "./tab1/W25F2011Expand1";
import W25F2011Expand2              from "./tab1/W25F2011Expand2";
import W25F2011Expand3              from "./tab1/W25F2011Expand3";
import W25F2011Expand4              from "./tab1/W25F2011Expand4";
import moment                       from "moment";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const styles = theme => {
    return {
        collapseBoardContent: {
            padding: '0 !important'
        },
        heading: {
            fontSize: 12,
            fontWeight: 'bold',
            textTransform: "uppercase",
            transform: "translateY(1px)",
            color: theme.palette.info.main
        },
        collapseBoard: {
            position: 'initial !important',
            boxShadow: 'initial !important',
            margin: '0 0 5px 0 !important'
        },
        ExpansionPanelSummary: {
            minHeight: 'initial !important',
            padding: "0 !important",
            width: "100%",
            color: theme.palette.primary.main,
            "& .MuiExpansionPanelSummary-content": {
                margin: "0 !important"
            },
            "&.Mui-expanded": {
                minHeight: "inherit"
            }
        },
    };
};
const listExpands = [
    {
        ID: 0,
        name: "DHR_Thong_tin_chung",
        expanded: true
    },
    {
        ID: 1,
        name: "DHR_Noi_dung_tin_tuyen_dung",
        expanded: true
    },
    {
        ID: 2,
        name: "DHR_Thanh_vien",
        expanded: true
    },
    {
        ID: 3,
        name: "Deadline",
        expanded: true
    },
];
class W25F2011Info extends React.PureComponent {
    constructor(props) {
        super(props);
        let initTabStatus = {};
        listExpands.map(e => {
            initTabStatus["tab" + e.ID] = !!e.expanded;
            return e;
        });
        this.dataForm = {
            RecInfoTitle: "",
            RecPositionID: "",
            DepartmentID: "",
            SalaryFrom: "",
            SalaryTo: "",
            CurrencyID: "VND",
            IsShowSalary: 0,
            NumPerson: 0,
            WorkingTypeID: "",
            ReceiveDateFrom: null,
            ReceiveDateTo: null,
            ImageURL: "",
            ContentInfo: "",
            ListMember: [],
            Deadline: null,
            IsHot: false,
            RecruitmentFileID: '',
            AgeFrom: '',
            AgeTo: ''
        };
        this.state = {
            expanded: {},
            isReset: 1,
            dataForm: this.dataForm,
            ...initTabStatus
        };
        this.timerChange = null;
        this.oldData = JSON.parse(JSON.stringify(this.dataForm));
        this.isLoaded = false;
        this.timer = null;
        this.refW25F2011Expand2 = null;
    }

    loadCboDepartment = () => {
        const param = {
            HostID: "",
            FormID: "W25F2011",
            DivisionID: Config.getDivisionID(),
            Language: Config.language || "84"
        };

        this.props.generalActions.getCboDepartments(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboDuty = () => {
        this.props.generalActions.getCboDuty2((errors) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    loadCboWorkingType = () => {
        this.props.W25F2011Actions.getCboWorkingType(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCurrency = () => {
        this.props.generalActions.getLoadCurrency({}, (error) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    loadCboRecruitmentFile = () => {
        this.props.W25F2011Actions.getCboRecruitmentFile({}, error => {
            if (error) {
                const message = error?.message ?? Config.lang("Loi_chua_xac_dinh");
                Config.popup2.show('info', message);
            }
        });
    };

    loadForm = () => {
        const { loadForm } = this.props;
        if (loadForm) {
            loadForm(0, (error, data) => {
                this.isLoaded = true;
                this._setData(data, () => {
                    this.oldData = JSON.parse(JSON.stringify(this.dataForm));
                    this.isLoaded = false;
                });
            });
        }
    };

    _setData = (data, cb) => {
        if (!data) return false;
        this.dataForm = Object.assign(this.dataForm, data);
        this.setState({
            dataForm: {
                ...this.dataForm,
            },
        }, () => {
            cb && cb();
        });
    };

    componentDidMount () {
        this.loadCboDepartment();
        this.loadCboWorkingType();
        this.loadCboDuty();
        this.loadCurrency();
        this.loadCboRecruitmentFile();
        const {RecInfoID} = this.props.data;
        if (RecInfoID) {
            this.loadForm();
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps.data && this.props.data && prevProps.data.RecInfoID !== this.props.data.RecInfoID) {
            const {RecInfoID} = this.props.data;
            if (RecInfoID) {
                this.loadForm();
            }
        }
    }

    setStateErrorText = (objValue, key) => {
        const { error } = this.state;
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...error,
                ...objValue,
            },
        });
        return Object.keys(objValue).length !== 0;
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        // e.persist();
        let value = _.get(e,"value", _.get(e,"target.value", ""));
        const {onChange} = this.props;
        if (!this.isLoaded) {
            if(key === "ContentInfo") {
                // if (this.timer) clearTimeout(this.timer);
                // this.timer = setTimeout(() => {
                    // this._setData({[key]: value}, () => {
                        if (onChange) onChange(key, e, {...this.dataForm,[key]: value}, this.oldData);
                        setTimeout(() => {
                            const result = MForm.isRequired(value, key);
                            this.setStateErrorText(result ? result : {[key]: ""});
                        }, 200);
                    // });
                // }, 500);

            } else if (key === 'RecruitmentFileID') {
                this._setData({[key]: value}, () => {
                    value && this.loadDefault(key, e);
                })
            } else {
                this._setData({[key]: value}, () => {
                    if (onChange) onChange(key, e, this.dataForm, this.oldData);
                    const unrequiredFields = ['AgeFrom', 'AgeTo'];
                    if (!unrequiredFields.includes(key)) {
                        setTimeout(() => {
                            const result = MForm.isRequired(value, key);
                            this.setStateErrorText(result ? result : {[key]: ""});
                        }, 200);
                    }
                });
            }

        }
    };

    loadDefault = (key, e) => {
        if (!key || !e) return false;
        let value = _.get(e,"value", _.get(e,"target.value", ""));
        const {onChange} = this.props;

        const params = {
            [key]: value
        };
        this.props.W25F2011Actions.loadDefault(params, (error, data) => {
            if (error) {
                const message = error?.message ?? Config.lang('Loi_chua_xac_dinh');
                Config.popup2.show('info', message);
            }
            if (data) {
                const newDataForm = {
                    RecPositionID: data?.RecPositionID ?? '',
                    DepartmentID: data?.DepartmentID ?? '',
                    SalaryFrom: data?.SalaryFrom ?? '',
                    SalaryTo: data?.SalaryTo ?? '',
                    CurrencyID: data?.CurrencyID ?? '',
                    NumPerson: data?.NumPerson ?? '',
                    AgeFrom: data?.AgeFrom ?? '',
                    AgeTo: data?.AgeTo ?? '',
                }
                this._setData(newDataForm, () => {
                    if (onChange) onChange(key, e, this.dataForm, this.oldData);
                });
            }
        });
    };

    getData = () => {
        return this.dataForm;
    };

    saveData = async () => {
        const {RecInfoTitle, RecPositionID, DepartmentID, SalaryFrom, SalaryTo, CurrencyID, IsShowSalary,
            NumPerson, WorkingTypeID, ReceiveDateFrom, ReceiveDateTo, ImageURL, ListMember, Deadline, IsHot, 
            RecruitmentFileID, AgeFrom, AgeTo,
        } = this.dataForm;
        const {mode, RecInfoID} = this.props.data;
        const {isCopy} = this.props;
        const _ListMember = ListMember
            ? (Array.isArray(ListMember) ? ListMember : ListMember.replace(/,/g, ';').split(';'))
            : [];

        let params = {
            FormID: "W25F2011",
            Mode: mode === "edit" ? 1 : 0,
            Type: isCopy ? 1 : 0,
            RecInfoTitle: RecInfoTitle,
            RecPositionID: RecPositionID,
            DepartmentID: DepartmentID,
            SalaryFrom: SalaryFrom,
            SalaryTo: SalaryTo,
            CurrencyID: CurrencyID,
            IsShowSalary: IsShowSalary ? IsShowSalary : 0,
            NumPerson: NumPerson ? NumPerson : 0,
            WorkingTypeID: WorkingTypeID,
            ReceiveDateFrom: ReceiveDateFrom ? ReceiveDateFrom : null,
            ReceiveDateTo: ReceiveDateTo ? ReceiveDateTo : null,
            ImageURL: ImageURL,
            ContentInfo: this.refW25F2011Expand2 ? this.refW25F2011Expand2.getContentEditor() : "",
            ListMember: _ListMember && _ListMember.length > 0 ? JSON.stringify(_ListMember) : null,
            Deadline: Deadline ? Deadline : null,
            IsHot: +IsHot ?? 0,
            RecruitmentFileID: RecruitmentFileID ?? '',
            AgeFrom: AgeFrom ?? '',
            AgeTo: AgeTo ?? '',
        };
        if (mode === "edit" || isCopy) {
            params.RecInfoID = RecInfoID;
        }
        const requiredFields = ["RecInfoTitle", "RecPositionID", "DepartmentID", "SalaryFrom", "SalaryTo", "CurrencyID",
            "NumPerson", "WorkingTypeID", "ReceiveDateFrom", "ReceiveDateTo", "ImageURL", "ContentInfo", "ListMember", "Deadline"
        ];
        const validateRules = [];
        requiredFields.forEach((item) => {
            validateRules.push({
                name: item,
                rules: ["isRequired"],
                value: (params && params[item]) ? params[item] : "",
            });
        });
        const validateForm = MForm.formValidation(validateRules);
        if (this.setStateErrorText(validateForm)) return false; //Neu co loi validate

        //Check Salary..
        if (Number(SalaryFrom) > Number(SalaryTo)) {
            Config.popup.show("INFO", Config.lang("DHR_Luong_tu_phai_nho_hon_luong_den"));
            return false;
        }

        if (moment(ReceiveDateFrom) > moment(ReceiveDateTo) || moment(ReceiveDateTo) > moment(Deadline)) {
            Config.popup.show("INFO", Config.lang("DHR_Thoi_gian_nop_hoac_deadline_khong_hop_le"));
            return false;
        }

        if (Number(AgeFrom) > Number(AgeTo)) {
            Config.popup2.show('info', Config.lang("Tuoi_tu_phai_nho_hon_tuoi_den"));
            return false;
        }

        return new Promise(resolve => {
            this.props.W25F2011Actions.saveDataInfo(params, async (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return resolve({success: false, data: null});
                }
                if (data) {
                    if (data.Status === 1) {
                        Config.popup.show("INFO", data.Message);
                        return resolve({success: false, data: null});
                    } else {
                        Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                        return resolve({success: true, data: data});
                    }
                }
            });
        });

    };

    onValidate = (key, e) => {
        if (!key || !e) return false;
        let value = _.get(e,"value", _.get(e,"target.value", ""));
        const {SalaryFrom, SalaryTo, AgeFrom, AgeTo} = this.dataForm;
        let msg = Config.lang("DHR_Luong_tu_phai_nho_hon_luong_den");
        let result = null;
        switch (key) {
            case "SalaryFrom":
                if (!Config.isEmpty(SalaryFrom, true)
                    && !Config.isEmpty(SalaryTo, true) && Number(SalaryFrom) > Number(SalaryTo)) {
                    result = {
                        SalaryFrom: msg,
                        SalaryTo:   msg
                    };
                } else {
                    result = MForm.isRequired(value, key);
                    const resultTo = MForm.isRequired(SalaryTo, "SalaryTo");
                    result = Object.assign(result, resultTo);
                }
                this.setStateErrorText(result);
                break;
            case "SalaryTo":
                if (!Config.isEmpty(SalaryTo, true)
                    && !Config.isEmpty(SalaryFrom, true) && Number(SalaryTo) < Number(SalaryFrom)) {
                    result = {
                        SalaryFrom: msg,
                        SalaryTo:   msg
                    };
                } else {
                    result = MForm.isRequired(value, key);
                    const resultFrom = MForm.isRequired(SalaryFrom, "SalaryFrom");
                    result = Object.assign(result, resultFrom);
                }
                this.setStateErrorText(result);
                break;
            case "RecruitmentFileID":
                break;
            case "AgeFrom":
                if (typeof AgeFrom === 'number' && typeof AgeTo === 'number' && Number(AgeFrom) > Number(AgeTo)) {
                    msg = Config.lang("Tuoi_tu_phai_nho_hon_tuoi_den");
                    result = {
                        AgeFrom: msg,
                        AgeTo:   msg
                    };
                }
                else {
                    result = {
                        AgeFrom: '',
                        AgeTo:   ''
                    };
                }
                this.setStateErrorText(result);
                break;
            case "AgeTo":
                if (typeof AgeFrom === 'number' && typeof AgeTo === 'number' && Number(AgeFrom) > Number(AgeTo)) {
                    msg = Config.lang("Tuoi_tu_phai_nho_hon_tuoi_den");
                    result = {
                        AgeFrom: msg,
                        AgeTo:   msg
                    };
                }
                else {
                    result = {
                        AgeFrom: '',
                        AgeTo:   ''
                    };
                }
                this.setStateErrorText(result);
                break;
            default:
                result = MForm.isRequired(value, key);
                this.setStateErrorText(result);
                break;
        }
    };

    renderContentTab = (ID) => {
        if (!ID && ID !== 0) return null;
        const {error, dataForm} = this.state;
        switch (Number(ID)) {
            case 0: return <W25F2011Expand1 dataForm={dataForm} error={error} onChange={this.handleChange} onValidate={this.onValidate}/>;
            case 1: return <W25F2011Expand2 ref={ref => this.refW25F2011Expand2 = ref} dataForm={dataForm} error={error} onChange={this.handleChange} onValidate={this.onValidate}/>;
            case 2: return <W25F2011Expand3 dataForm={dataForm} error={error} onChange={this.handleChange} onValidate={this.onValidate}/>;
            case 3: return <W25F2011Expand4 dataForm={dataForm} error={error} onChange={this.handleChange} onValidate={this.onValidate}/>;
            default:
                break;
        }
    };

    render() {
        const { btnSave, classes } = this.props;
        const { dataForm } = this.state;
        return (
            <React.Fragment>
                {listExpands && listExpands.map(expand => {
                    return (
                        <FormGroup key={expand.ID}>
                            <ExpansionPanel
                                expanded={this.state["tab" + expand.ID] || false}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ ["tab" + expand.ID]: !this.state["tab" + expand.ID] })}
                            >
                                <ExpansionPanelSummary className={classes.ExpansionPanelSummary} >
                                    <Icons name={!!this.state["tab" + expand.ID] ? "up" : "down"} className={"mgr5"}/>
                                    <Typography className={classes.heading}>{Config.lang(expand.name)}</Typography>
                                    {expand.ID === 3 && <span className={"mgl5"} style={{color: "red"}}>*</span>}
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                    {this.renderContentTab(expand.ID)}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </FormGroup>
                    );
                })}
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{paddingLeft: 0}}
                                color="primary"
                                checked={Boolean(dataForm?.IsHot)}
                            />
                        }
                        onClick={(e) => this.handleChange("IsHot", {value: Number(e.target.checked)})}
                        label={Config.lang("Tin_hot")}
                    />
                </FormGroup>
                <FormGroup>
                    {btnSave && btnSave}
                </FormGroup>
            </React.Fragment>
        );
    }

}

W25F2011Info.propTypes = {
    data: PropTypes.object,
    loadForm: PropTypes.func,
    onChange: PropTypes.func
};

export default compose(connect((state) => ({
    getCboDepartments: state.general.getCboDepartments,
}), (dispatch) => ({
    W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
}), null, {forwardRef: true}
), withStyles(styles))(W25F2011Info);