/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/29/2020
 * @Example 
 */
import { TextInput } from 'diginet-core-ui/components';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Config from "../../../../config";
class W25F2002EducationInfo extends Component {
    render() {
        const { onChange, formData, disabled } = this.props;
        const { ComputerSkills, EnglishLevel, OtherRequest } = formData;
        return (
            <>
                <div style={{ width: "100%" }}>
                    <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <TextInput
                                label={Config.lang("Trinh_do_tin_hoc")}
                                onChange={(e) => { if (onChange) onChange("ComputerSkills", e) }}
                                disabled={disabled}
                                value={ComputerSkills}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <TextInput
                                label={Config.lang("Trinh_do_ngoai_ngu")}
                                onChange={(e) => { if (onChange) onChange("EnglishLevel", e) }}
                                disabled={disabled}
                                value={EnglishLevel}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                label={Config.lang("Yeu_cau_khac")}
                                onChange={(e) => { if (onChange) onChange("OtherRequest", e) }}
                                disabled={disabled}
                                value={OtherRequest}
                            />
                        </Col>
                    </Row>
                </div>

            </>
        );
    }
}

W25F2002EducationInfo.propTypes = {
    disabled: PropTypes.bool,
    formData: PropTypes.object,
    onChange: PropTypes.func,
};

export default W25F2002EducationInfo;