/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/25/2020
 * @Example
 */

import { FormControl } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Accordion, AccordionDetails, AccordionSummary, Attachment, Button, DatePicker,
    Dropdown, Label, MoneyInput, TextInput, Typography, DateRangePicker, NumberInput
} from 'diginet-core-ui/components';
import Icon from 'diginet-core-ui/icons';
import _ from "lodash";
import moment from 'moment';
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W25F2002Actions from "../../../../redux/W2X/W25F2002/W25F2002_actions";
import MForm from "../../../common/form-material/form";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import W25F2002EducationInfo from "../../../W2X/W25/W25F2002/W25F2002EducationInfo";
import W25F2002EmployeeInfo from "../../../W2X/W25/W25F2002/W25F2002EmployeeInfo";

const cboDay = [
    {
        SalUnitID: "H",
        SalUnitNameVi: "Giờ",
        SalUnitName: "Hour"
    },
    {
        SalUnitID: "D",
        SalUnitNameVi: "Ngày",
        SalUnitName: "Day"
    },
    {
        SalUnitID: "M",
        SalUnitNameVi: "Tháng",
        SalUnitName: "Month"
    },
    {
        SalUnitID: "Y",
        SalUnitNameVi: "Năm",
        SalUnitName: "Year"
    },
];
const styles = theme => ({
    ExpansionPanelDetails: {
        padding: 0
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    statusColor: {
        display: "inline-block",
        color: "#333333",
        fontSize: ".9rem",
        marginTop: "12px",
        textAlign: "center",
        padding: "12px 5px",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",

        "&.success": {
            backgroundColor: theme.palette.success.main
        },
        "&.warning": {
            backgroundColor: theme.palette.warning.main
        }
    },
    numberStatus: {
        display: "inline-block",
        marginTop: "40px",
        color: theme.palette.danger.main
    },
    textField: {
        '& .MuiFormHelperText-root': {
            marginTop: 0,
            position: "relative",
            top: 0
        }
    }
});

class W25F2002 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCboProjects: {
                rows: [],
                total: 0
            },
            error: {},
            loadingProjects: false,
            expandedFirst: false,
            expandedSecond: false,
            count: {},
            disabled: false,
            formData: {
                DepartmentID: "",
                TeamID: "",
                ProjectID: "",
                Description: "",
                DutyID: "",
                ProNumber: 1,
                VoucherDate: moment().format("YYYY-MM-DD"),
                DateFrom: null,
                DateTo: null,
                SalaryFrom: 0,
                SalaryTo: 0,
                CurrencyID: "VND",
                SalUnitID: "M",
                ReasonRequest: "",
                MaleQuan: "",
                FemaleQuan: "",
                WorkID: "",
                WorkPlace: "",
                AgeFrom: null,
                AgeTo: null,
                Personality: "",
                ProNoteU: "",
                TransID: "",
                Experienced: "",
                EducationLevelID: "",
                ProfessionalLevelID: "",
                Capability: "",
                ComputerSkills: "",
                EnglishLevel: "",
                OtherRequest: "",
                ProApproved: 0,
                PlanTransID: "",
                ReasonID: "",
                EducationLevelIDArr: [],
                ProfessionalLevelIDArr: [],
            },
            disableAttachment: false,
            uploading: false,
            formLoading: false,
            cboPlanLoading: false,
            cboReasonLoading: false,
            cboDepartmentsLoading: false,
            cboTeamLoading: false,
            cboDutyLoading: false,
            cboCurrencyLoading: false,
            cboWorkLoading: false,
            cboEducationLoading: false,
            cboProfessionalLevelLoading: false
        };

        this.attachments = [];
        this.deletedFile = [];
        this.getAttachment = [];
        this.upload = null;
        this.filterCboProjects = {
            skip: 0,
            limit: 50,
            strSearch: ""
        }


    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ['loading' + key]: status });

    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onUploading = value => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e.allNewAttached;
        if (!_.isEmpty(this.getAttachment) && this.getAttachment.length > e.oldAttached.length) {
            this.getAttachment = e.oldAttached;
        }
    };

    loadCboDepartments = () => {
        const param = {
            DivisionID: Config.getDivisionID(),
            CompanyID: "",
            HostID: "",
            FormID: "W25F2002",
            Language: Config.language || '84'
        };
        this.setState({ cboDepartmentsLoading: true });
        this.props.generalActions.getCboDepartments(param, (error) => {
            this.setState({ cboDepartmentsLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboTeams = () => {
        const param = {
            DivisionID: Config.getDivisionID(),
            CompanyID: "",
            HostID: "",
            FormID: "W25F2002",
            Language: Config.language || '84'
        };
        this.setState({ cboTeamLoading: true });
        this.props.generalActions.getCboTeam(param, (error) => {
            this.setState({ cboTeamLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };


    loadCboProjects = (isReset) => {
        const param = {
            HostID: "",
            FormID: "W25F2002",
            Language: Config.language || '84',
            Mode: 0,
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.setLoading('Projects', true);
        this.props.generalActions.getCboProjects(param, (error, data) => {
            this.setLoading('Projects', false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    onChange = (nameField, e) => {
        if (!e) return false;
        let value = _.get(e, "value", _.get(e, "target.value", ""));
        const { formData, error } = this.state;
        if ((nameField === "SalaryFrom" || nameField === "SalaryTo") && Number(value) < 0) {
            value = 0;
        }

        if (nameField === "SalaryTo") {
            if (value < Number(formData.SalaryFrom)) {
                this.setStateErrorText({ "SalaryTo": Config.lang("Muc_luong_den_khong_duoc_nho_hon_muc_luong_tu") });
            } else {
                this.setStateErrorText({ "SalaryFrom": "", "SalaryTo": "" });
            }
        }

        if (nameField === "SalaryFrom") {
            if (value > Number(formData.SalaryTo)) {
                this.setStateErrorText({ "SalaryFrom": Config.lang("Muc_luong_tu_khong_duoc_lon_muc_luong_den") });
            } else {
                this.setStateErrorText({ "SalaryFrom": "" });
            }
        }

        if (nameField === "AgeTo" || nameField === "AgeFrom") {
            if ((nameField === "AgeTo" && !_.isNull(formData.AgeFrom) && value <= Number(formData.AgeFrom)) ||
                (nameField === "AgeFrom" && !_.isNull(formData.AgeTo) && value > Number(formData.AgeTo))) {
                this.setStateErrorText({ "AgeTo": Config.lang("Tuoi_cao_khong_duoc_nho_hon_tuoi_co_ban") });
            } else {
                if (!_.isEmpty(error.AgeTo)) this.setStateErrorText({ "AgeTo": "" });
            }
        }

        if (nameField === "rangePicker") {
            this.setState({
                formData: {
                    ...this.state.formData,
                    DateFrom: _.isArray(value) ? value[0] : value,
                    DateTo: _.isArray(value) ? value[1] : value,
                },
                error: _.isArray(value) ? _.omit(error, ["DateFrom", "DateTo"]) : error
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [nameField]: value
                }
            }, () => {
                if (error && error.hasOwnProperty(nameField)) { // Remove error
                    this.setState({ error: _.omit(error, nameField) });
                }
                if (nameField === "ProjectID" || nameField === "DepartmentID" || nameField === "TeamID" || nameField === "ProNumber" || nameField === "WorkID" || nameField === "DutyID") {
                    this.loadCount();
                } else if (nameField === "PlanTransID" && !_.isEmpty(value)) {
                    this.loadForm(value, 1); // Load theo PlanTransID
                }
            });
        }
    };

    loadCboWorks = () => {
        const param = {
            Language: Config.language || '84'
        };
        this.setState({ cboWorkLoading: true });
        this.props.generalActions.getCboWorks(param, (error) => {
            this.setState({ cboWorkLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboPositions = () => {
        this.setState({ cboDutyLoading: true });
        this.props.generalActions.getCboDuty2((error) => {
            this.setState({ cboDutyLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCurrency = () => {
        // this.setState({ cboCurrencyLoading: true });
        this.props.generalActions.getLoadCurrency({}, (error, data) => {
            // this.setState({ cboCurrencyLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            // else if (data) {
            //     const findDefault = data.find(item => item.CurrencyID === "VND");
            //     if (!_.isEmpty(findDefault)) this.setState({
            //         formData: { ...this.state.formData, CurrencyID: findDefault.CurrencyID }
            //     }); // Set dafult là VND
            // }
        });
    };

    loadEducation = () => {
        // this.setState({ cboEducationLoading: true });
        this.props.generalActions.getLoadEducation({}, (error) => {
            // this.setState({ cboEducationLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    LoadProfessionalLevel = () => {
        // this.setState({ cboProfessionalLevelLoading: true });
        this.props.generalActions.getLoadProfessionalLevel({}, (error) => {
            // this.setState({ cboProfessionalLevelLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadAttachments = () => {
        const { TransID } = this.getInfo();
        const param = {
            KeyID: TransID ? TransID : "",
            TableName: "D25T2001_TD"
        };
        if (_.isEmpty(param.KeyID)) {
            Config.popup.show("INFO", Config.lang("KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem"));
            return false;
        }
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (!_.isEmpty(data)) {
                this.getAttachment = data;
                this.setState({ disableAttachment: false }); // Do dùng Ref nên cần re-render attachment
            }
        });
    };

    loadForm = (PlanTransID, Mode = 0) => {
        const { TransID } = this.getInfo();
        const { PlanTransID: PlanTransIDSate = "" } = this.state.formData;
        const params = {
            Mode,
            TransID: TransID,
            DivisionID: Config.getDivisionID(),
            Language: Config.language || "84",
            PlanTransID: PlanTransID ? PlanTransID : PlanTransIDSate,
        };

        this.props.W25F2002Actions.getLoadForm(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({
                    count: {
                        StatusQty: data.StatusQty ? data.StatusQty : 0,
                        StatusQtyName: data.StatusQtyName ? data.StatusQtyName : "",
                        OverQty: data.OverQty ? data.OverQty : 0,
                    },
                    formData: {
                        ...this.state.formData,
                        DepartmentID: data.DepartmentID ? data.DepartmentID : "",
                        TeamID: data.TeamID ? data.TeamID : "",
                        ProjectID: data.ProjectID ? data.ProjectID : "",
                        Description: data.Description ? data.Description : "",
                        DutyID: data.DutyID ? data.DutyID : "",
                        ProNumber: data.ProNumber ? data.ProNumber : 1,
                        VoucherDate: data.VoucherDate ? data.VoucherDate : moment().format("YYYY-MM-DD"),
                        DateFrom: data.DateFrom ? data.DateFrom : null,
                        DateTo: data.DateTo ? data.DateTo : null,
                        SalaryFrom: data.SalaryFrom ? data.SalaryFrom : 0,
                        SalaryTo: data.SalaryTo ? data.SalaryTo : 0,
                        CurrencyID: data.CurrencyID ? data.CurrencyID : "VND",
                        SalUnitID: data.SalUnitID ? data.SalUnitID : "M",
                        ReasonRequest: data.ReasonRequest ? data.ReasonRequest : "",
                        MaleQuan: data.MaleQuan ? data.MaleQuan : "",
                        FemaleQuan: data.FemaleQuan ? data.FemaleQuan : "",
                        WorkID: data.WorkID ? data.WorkID : "",
                        WorkPlace: data.WorkPlace ? data.WorkPlace : "",
                        AgeFrom: data.AgeFrom ? data.AgeFrom : null,
                        AgeTo: data.AgeTo ? data.AgeTo : null,
                        Personality: data.Personality ? data.Personality : "",
                        ProNoteU: data.ProNote ? data.ProNote : "",
                        TransID: data.TransID ? data.TransID : "",
                        Experienced: data.Experienced ? data.Experienced : "",
                        EducationLevelID: data.EducationLevelID ? data.EducationLevelID : "",
                        ProfessionalLevelID: data.ProfessionalLevelID ? data.ProfessionalLevelID : "",
                        EducationLevelIDArr: data.EducationLevelID ? data.EducationLevelID.split(",") : [],
                        ProfessionalLevelIDArr: data.ProfessionalLevelID ? data.ProfessionalLevelID.split(",") : [],
                        Capability: data.Capability ? data.Capability : "",
                        ComputerSkills: data.ComputerSkills ? data.ComputerSkills : "",
                        EnglishLevel: data.EnglishLevel ? data.EnglishLevel : "",
                        OtherRequest: data.OtherRequest ? data.OtherRequest : "",
                        ProApproved: data.ProApproved ? data.ProApproved : 0,
                        PlanTransID: PlanTransID ? PlanTransID : _.get(data, "PlanTransID", ""),
                        PlanName: data.PlanName ? data.PlanName : "",
                        ReasonID: data.ReasonID ? data.ReasonID : "",
                        ReasonName: data.ReasonName ? data.ReasonName : "",
                    }
                }, () => {
                    if (Mode === 1) {
                        this.loadCount(); // Chọn PlanTransID load lại Count
                    }
                });
            }
        })
    };

    loadCount = () => {
        const { formData } = this.state;
        const params = {
            DivisionID: Config.getDivisionID(),
            DepartmentID: formData.DepartmentID ? formData.DepartmentID : "",
            TeamID: formData.TeamID ? formData.TeamID : "",
            DutyID: formData.DutyID ? formData.DutyID : "",
            WorkID: formData.WorkID ? formData.WorkID : "",
            ProjectID: formData.ProjectID ? formData.ProjectID : "",
            ProNumber: formData.ProNumber ? formData.ProNumber : "",
        };

        this.props.W25F2002Actions.getCount(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({
                    count: {
                        StatusQty: data.StatusQty ? data.StatusQty : 0,
                        StatusQtyName: data.StatusQtyName ? data.StatusQtyName : "",
                        OverQty: data.OverQty ? data.OverQty : "",
                    }
                })
            }
        })
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    onSave = () => {
        const { location } = this.props;
        const mode = _.get(location, "state.mode", "add");
        const apiSave = mode === "add" ? mode : "edit";
        const TransID = _.get(location, "state.TransID", false);
        const { formData, count } = this.state;
        const {
            DepartmentID,
            TeamID,
            ProjectID,
            Description,
            DutyID,
            ProNumber,
            VoucherDate,
            DateFrom,
            DateTo,
            SalaryFrom,
            SalaryTo,
            CurrencyID,
            SalUnitID,
            ReasonRequest,
            MaleQuan,
            FemaleQuan,
            WorkID,
            WorkPlace,
            AgeFrom,
            AgeTo,
            Personality,
            ProNoteU,
            Experienced,
            EducationLevelIDArr,
            ProfessionalLevelIDArr,
            Capability,
            ComputerSkills,
            EnglishLevel,
            OtherRequest,
            ProApproved,
            PlanTransID,
            ReasonID
        } = formData;
        if (Number(SalaryTo) < Number(SalaryFrom)) {
            this.setStateErrorText({ SalaryTo: Config.lang("Muc_luong_den_khong_duoc_nho_hon_muc_luong_tu") });
            return;
        }

        if (Number(SalaryFrom) > Number(SalaryTo)) {
            this.setStateErrorText({ SalaryTo: Config.lang("Muc_luong_tu_khong_duoc_lon_muc_luong_den") });
            return;
        }

        if (AgeTo < AgeFrom) {
            this.setStateErrorText({ "AgeTo": Config.lang("Tuoi_cao_khong_duoc_nho_hon_tuoi_co_ban") });
            return;
        }
        const validateRules = [
            {
                name: "DepartmentID",
                rules: "isRequired",
                value: DepartmentID
            },
            {
                name: "VoucherDate",
                rules: "isRequired",
                value: VoucherDate
            },
            {
                name: "DateFrom",
                rules: "isRequired",
                value: DateFrom
            },
            {
                name: "DateTo",
                rules: "isRequired",
                value: DateTo
            },
            {
                name: "ProNumber",
                rules: "isRequired",
                value: ProNumber
            },
            {
                name: "DutyID",
                rules: "isRequired",
                value: DutyID
            }
        ];
        const validateFrom = MForm.formValidation(validateRules);
        if (Object.keys(validateFrom).length > 0) {
            this.setStateErrorText(validateFrom);
            return false;
        }
        const arrAttachments = this.getAttachment.concat(this.attachments);
        let params = {
            TransID: TransID,
            VoucherDate: VoucherDate,
            DivisionID: Config.getDivisionID(),
            DepartmentID: DepartmentID,
            TeamID: TeamID,
            ProjectID: ProjectID ? ProjectID : "",
            Description: Description,
            DutyID: DutyID,
            ProNumber: ProNumber ? ProNumber : 1,
            StatusQty: count && count.StatusQty ? count.StatusQty : 0,
            DateFrom: DateFrom,
            DateTo: DateTo,
            SalaryFrom: SalaryFrom ? SalaryFrom : 0,
            SalaryTo: SalaryTo ? SalaryTo : 0,
            CurrencyID: CurrencyID,
            SalUnitID: SalUnitID,
            ReasonRequest: ReasonRequest,
            MaleQuan: MaleQuan ? MaleQuan : 0,
            FemaleQuan: FemaleQuan ? FemaleQuan : 0,
            WorkID: WorkID,
            WorkPlace: WorkPlace,
            AgeFrom: AgeFrom ? AgeFrom : 0,
            AgeTo: AgeTo ? AgeTo : 0,
            Personality: Personality,
            ProNoteU: ProNoteU,
            Experienced: Experienced,
            EducationLevelID: EducationLevelIDArr.toString(),
            ProfessionalLevelID: ProfessionalLevelIDArr.toString(),
            Capability: Capability,
            ComputerSkills: ComputerSkills,
            EnglishLevel: EnglishLevel,
            OtherRequest: OtherRequest,
            ProApproved: ProApproved ? ProApproved : 0,
            Language: Config.language || '84',
            arrAttachment: arrAttachments ? JSON.stringify(arrAttachments) : "",
            PlanTransID,
            ReasonID,
        };
        if (TransID && apiSave === "edit") params = { ...params, TransID };
        this.setState({ formLoading: true });
        this.props.W25F2002Actions[apiSave](params, (errors, data) => {
            this.setState({ formLoading: false });
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data.Status === 1) {
                const msg = data.Message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', msg);
                return false;
            } else {
                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                this.onBack();
            }

        });
    };

    getInfo = (flag, nextProps) => {
        const { location } = nextProps ? nextProps : this.props;
        if (location && location.state) {
            const voucher_id = location.state.voucher_id ? location.state.voucher_id : "";
            return {
                mode: location.state.mode ? location.state.mode : (voucher_id ? 'edit' : 'add'),
                TransID: location.state.TransID ? location.state.TransID : voucher_id,
                AppStatusID: location.state.AppStatusID,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W25F2000");
                return null;
            } else {
                const { data, mode } = this.props;
                return {
                    mode: mode,
                    TransID: data && data.TransID ? data.TransID : "",
                }
            }
        }
    };

    setCboLoading = (actionCboName = "", status) => {
        let cboLoadingName = "";
        switch (actionCboName) {
            case "getCboPlan":
                cboLoadingName = "cboPlanLoading";
                break;
            case "getCboReason":
                cboLoadingName = "cboReasonLoading";
                break;
            default:
                break;
        }
        if (cboLoadingName && _.isBoolean(status)) this.setState({ [cboLoadingName]: status });
    }

    loadCbo = (actionCboName = "") => {
        if (_.isEmpty(actionCboName)) return;
        this.setCboLoading(actionCboName, true);
        this.props.W25F2002Actions[actionCboName]((error) => {
            this.setCboLoading(actionCboName, false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    }

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        const { mode, AppStatusID } = this.getInfo();
        const { dataCboPlan, dataCboReason } = this.props;
        this.loadCboDepartments();
        this.loadCboTeams();
        this.loadCboProjects();
        this.loadCurrency();
        this.loadCboWorks();
        this.loadEducation();
        this.LoadProfessionalLevel();
        this.loadCboPositions();
        if (_.isEmpty(dataCboPlan)) this.loadCbo("getCboPlan");
        if (_.isEmpty(dataCboReason)) this.loadCbo("getCboReason");
        if (mode === "edit" || mode === "view") {
            if (Number(AppStatusID) !== 0) {
                this.setState({ disabled: true });
            }
            this.loadForm();
            this.loadAttachments();
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { TransID } = this.getInfo(false, nextProps);
        const { formData } = this.state;
        if (TransID !== formData.TransID) {
            this.loadForm();
            this.loadAttachments();
        }
    };

    onBack = () => {
        browserHistory.push(Config.getRootPath() + "W25F2000");
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { AppStatusID } = this.getInfo();
        const {
            getCboDepartments,
            getCboDuty2,
            getCboTeam,
            getCboWorks,
            getLoadCurrency,
            getLoadEducation,
            getLoadProfessionalLevel,
            classes,
            dataCboPlan,
            dataCboReason,
        } = this.props;

        const { dataCboProjects, loadingProjects, formData, expandedFirst, expandedSecond, error, cboTeamLoading,
            cboDutyLoading, cboWorkLoading, disableAttachment, cboEducationLoading, cboProfessionalLevelLoading,
            formLoading, count, disabled, cboPlanLoading, cboReasonLoading, cboDepartmentsLoading, cboCurrencyLoading } = this.state;

        const {
            DepartmentID, TeamID, ProjectID, Description, DutyID, ProNumber, VoucherDate, DateFrom, PlanTransID, ReasonID, Capability,
            DateTo, SalaryFrom, SalaryTo, CurrencyID, SalUnitID, ReasonRequest, Experienced, EducationLevelIDArr, ProfessionalLevelIDArr,
        } = formData;

        return (
            <>
                <ActionToolbar title={Config.lang("Lap_de_xuat_tuyen_dung")} onBack={this.onBack} >
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <Button
                            disabled={formLoading || disabled || (AppStatusID > 0)}
                            size={"medium"}
                            color={"info"}
                            startIcon={"SaveV2"}
                            viewType={"filled"}
                            label={Config.lang("Luu")}
                            onClick={this.onSave}
                        />
                    </div>
                </ActionToolbar>
                <Row className={"mgt25 mgb25"}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Typography style={{ fontWeight: "bold" }} >
                            {Config.lang("Thong_tin_chung")}
                        </Typography>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={4} md={4} lg={4}>
                        <Dropdown
                            clearAble
                            valueExpr={"PlanTransID"}
                            keyExpr={"PlanName"}
                            displayExpr={"PlanName"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("Ke_hoach_tong_the")}
                            onChange={e => this.onChange("PlanTransID", e)}
                            disabled={disabled}
                            dataSource={dataCboPlan}
                            loading={cboPlanLoading}
                            value={PlanTransID}
                        />
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4}>
                        <Dropdown
                            clearAble
                            required={true}
                            error={error && error["DepartmentID"]}
                            valueExpr={"DepartmentID"}
                            keyExpr={"DepartmentName"}
                            displayExpr={"DepartmentName"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("Phong_ban")}
                            onChange={e => this.onChange("DepartmentID", e)}
                            readOnly={!_.isEmpty(PlanTransID)}
                            disabled={disabled}
                            dataSource={getCboDepartments}
                            loading={cboDepartmentsLoading}
                            value={DepartmentID}
                        />
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4}>
                        <Dropdown
                            clearAble
                            valueExpr={"TeamID"}
                            keyExpr={"TeamName"}
                            displayExpr={"TeamName"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("To_nhom")}
                            onChange={e => this.onChange("TeamID", e)}
                            disabled={disabled}
                            dataSource={getCboTeam}
                            loading={cboTeamLoading}
                            value={TeamID}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4}>
                        <Dropdown
                            clearAble
                            searchDelayTime={1000}
                            disabled={disabled}
                            dataSource={_.get(dataCboProjects, "rows", [])}
                            total={_.get(dataCboProjects, "total", 0)}
                            skip={this.filterCboProjects.skip}
                            limit={this.filterCboProjects.limit}
                            value={ProjectID}
                            loading={loadingProjects}
                            displayExpr={"ProjectName"}
                            keyExpr={"ProjectName"}
                            valueExpr={"ProjectID"}
                            label={Config.lang("Du_an")}
                            placeholder={Config.lang("Chon")}
                            onChange={(e) => this.onChange("ProjectID", e)}
                            onInput={(e) => {
                                this.filterCboProjects.strSearch = e.target.value;
                                this.filterCboProjects.skip = 0;
                                this.loadCboProjects(true);
                            }}
                            onLoadMore={(e) => {
                                this.filterCboProjects.skip = e.skip;
                                this.filterCboProjects.limit = e.limit;
                                this.loadCboProjects();
                            }}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={8} lg={8}>
                        <TextInput
                            multiline={true}
                            label={Config.lang("Mo_ta_cong_viec")}
                            onChange={(e) => this.onChange("Description", e)}
                            disabled={disabled}
                            value={Description}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4}>
                        <Dropdown
                            clearAble
                            required={true}
                            error={error && error["DutyID"]}
                            valueExpr={"DutyID"}
                            keyExpr={"DutyName"}
                            displayExpr={"DutyName"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("Vi_tri_tuyen_dung")}
                            onChange={e => this.onChange("DutyID", e)}
                            readOnly={!_.isEmpty(PlanTransID)}
                            disabled={disabled}
                            dataSource={getCboDuty2}
                            loading={cboDutyLoading}
                            value={DutyID}
                        />
                    </Col>
                    <Col xs={12} sm={3} md={2} lg={2}>
                        <NumberInput
                            disabledNegative
                            required={true}
                            error={error && error["ProNumber"]}
                            label={Config.lang("So_luong_can_tuyen")}
                            onChange={(e) => this.onChange("ProNumber", e)}
                            disabled={disabled}
                            value={ProNumber}
                        />
                    </Col>
                    {count.StatusQty !== 2 &&
                        <Col xs={12} sm={3} md={2} lg={2}>
                            <Row>
                                <Col xs={12} sm={12} md={7} lg={7}>
                                    {count.StatusQtyName &&
                                        <span className={classes.statusColor + " " + (count.StatusQty === 0 ? "success" : "warning")}>
                                            {count.StatusQtyName || ""}
                                        </span>}
                                </Col>
                                {count.OverQty > 0 &&
                                    <Col xs={5}>
                                        <span className={classes.numberStatus}>{"Vượt " + count.OverQty} </span>
                                    </Col>}
                            </Row>
                        </Col>
                    }
                    <Col xs={12} sm={6} md={4} lg={4}>
                        <DatePicker
                            clearAble
                            required={true}
                            width={"100%"}
                            margin={"normal"}
                            placeholder={"DD/MM/YY"}
                            displayFormat={"DD/MM/YYYY"}
                            label={Config.lang("Ngay_de_xuat")}
                            error={error && error["VoucherDate"]}
                            onChange={e => this.onChange("VoucherDate", e)}
                            disabled={disabled}
                            value={VoucherDate}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4}>
                        <DateRangePicker
                            clearAble
                            controls
                            value={[
                                DateFrom,
                                DateTo
                            ]}
                            required={true}
                            disabled={disabled}
                            error={error && (error["DateFrom"] || error["DateTo"])}
                            label={Config.lang("Thoi_gian_can_nhan_su")}
                            onChange={e => this.onChange("rangePicker", e)}
                            placeholder={"DD/MM/YY - DD/MM/YY"}
                            returnFormat={"YYYY-MM-DD"}
                        />
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={8}>
                        <FormControl
                            className={"mgt5"}
                            variant={"standard"}
                            fullWidth={true}>
                            <Label style={{ margin: 0 }}>{Config.lang("Muc_luong_du_kien")}</Label>
                            <Row className={"mgt5"}>
                                <Col xs={12} sm={3} md={3} lg={3}>
                                    <MoneyInput
                                        allowZero
                                        disabledNegative={true}
                                        thousandSeparator={","}
                                        disabled={disabled}
                                        value={SalaryFrom}
                                        error={error && error["SalaryFrom"]}
                                        onChange={(e) => this.onChange("SalaryFrom", e)}
                                    />
                                </Col>
                                <Col xs={12} sm={3} md={3} lg={3}>
                                    <MoneyInput
                                        allowZero
                                        disabledNegative={true}
                                        thousandSeparator={","}
                                        disabled={disabled}
                                        value={SalaryTo}
                                        error={error && error["SalaryTo"]}
                                        onChange={(e) => this.onChange("SalaryTo", e)}
                                    />
                                </Col>
                                <Col xs={12} sm={3} md={3} lg={3}>
                                    <Dropdown
                                        style={{ minWidth: 'auto' }}
                                        valueExpr={"CurrencyID"}
                                        keyExpr={"CurrencyName"}
                                        displayExpr={"CurrencyName"}
                                        onChange={e => this.onChange("CurrencyID", e)}
                                        disabled={disabled}
                                        dataSource={getLoadCurrency}
                                        loading={cboCurrencyLoading}
                                        value={CurrencyID}
                                    />
                                </Col>
                                <Col xs={12} sm={3} md={3} lg={3}>
                                    <Dropdown
                                        style={{ minWidth: 'auto' }}
                                        valueExpr={"SalUnitID"}
                                        keyExpr={Config.language === '84' ? "SalUnitNameVi" : "SalUnitName"}
                                        displayExpr={Config.language === '84' ? "SalUnitNameVi" : "SalUnitName"}
                                        onChange={e => this.onChange("SalUnitID", e)}
                                        disabled={disabled}
                                        value={SalUnitID}
                                        dataSource={cboDay}
                                    />
                                </Col>
                            </Row>
                        </FormControl>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4}>
                        <Dropdown
                            clearAble
                            valueExpr={"ReasonID"}
                            keyExpr={"ReasonName"}
                            displayExpr={"ReasonName"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("Ly_do_tuyen")}
                            onChange={e => this.onChange("ReasonID", e)}
                            dataSource={dataCboReason}
                            loading={cboReasonLoading}
                            value={ReasonID}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={8} lg={8}>
                        <TextInput
                            label={Config.lang("Mo_ta_ly_do_khac")}
                            onChange={(e) => this.onChange("ReasonRequest", e)}
                            disabled={disabled || ReasonID !== "OTHER"}
                            value={ReasonRequest}
                        />
                    </Col>
                </Row>
                {/* collap div employee */}
                <div>
                    <Accordion expand={expandedFirst}>
                        <AccordionSummary style={{ display: 'none' }} >
                        </AccordionSummary>
                        <AccordionDetails style={{ margin: 0, padding: 0 }}>
                            <W25F2002EmployeeInfo
                                disabled={disabled}
                                formData={formData}
                                getCboWorks={getCboWorks}
                                cboWorkLoading={cboWorkLoading}
                                onChange={(name, e) => this.onChange(name, e)}
                                error={error}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <div className={"display_col align-center valign-middle"}>
                        <span style={{ cursor: "pointer", textAlign: 'center' }} onClick={() => this.setState({ expandedFirst: !expandedFirst })}>
                            <Icon
                                height={32}
                                width={32}
                                color={"primary"}
                                style={{ cursor: "pointer", padding: 0 }}
                                name={expandedFirst ? "ArrowDoubleUp" : "ArrowDoubleDown"} />
                            <div className={classes.divText}>
                                <Typography> {expandedFirst ? Config.lang("Thu_nho") : Config.lang("Mo_rong")}</Typography>
                            </div>
                        </span>
                    </div>
                </div>

                <Row className={"mgt25 mgb25"}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Typography style={{ fontWeight: "bold" }} >
                            {Config.lang("Hoc_van_va_chuyen_mon")}
                        </Typography>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            label={Config.lang("Kinh_nghiem")}
                            onChange={(e) => this.onChange("Experienced", e)}
                            disabled={disabled}
                            value={Experienced}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <Dropdown
                            multiple
                            selectBox
                            closeAfterSelect={false}
                            itemMultipleSize={"small"}
                            valueExpr={"EducationLevelID"}
                            displayExpr={"EducationLevelName"}
                            label={Config.lang("Trinh_do_hoc_van")}
                            onChange={e => this.onChange("EducationLevelIDArr", e)}
                            disabled={disabled}
                            value={EducationLevelIDArr}
                            loading={cboEducationLoading}
                            dataSource={getLoadEducation}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <Dropdown
                            multiple
                            selectBox
                            closeAfterSelect={false}
                            itemMultipleSize={"small"}
                            valueExpr={"ProfessionalLevelID"}
                            displayExpr={"ProfessionalLevelName"}
                            label={Config.lang("Trinh_do_chuyen_mon")}
                            onChange={e => this.onChange("ProfessionalLevelIDArr", e)}
                            disabled={disabled}
                            value={ProfessionalLevelIDArr}
                            loading={cboProfessionalLevelLoading}
                            dataSource={getLoadProfessionalLevel}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            label={Config.lang("Ky_nang")}
                            onChange={(e) => this.onChange("Capability", e)}
                            disabled={disabled}
                            value={Capability}
                        />
                    </Col>
                </Row>
                {/*    collap div education */}
                <div>
                    <Accordion expand={expandedSecond}>
                        <AccordionSummary style={{ display: 'none' }} >
                        </AccordionSummary>
                        <AccordionDetails style={{ margin: 0, padding: 0 }}>
                            <W25F2002EducationInfo
                                disabled={disabled}
                                formData={formData}
                                onChange={(name, e) => this.onChange(name, e)}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <div className={"display_col align-center valign-middle"} >
                        <span style={{ cursor: "pointer", textAlign: 'center' }} onClick={() => this.setState({ expandedSecond: !expandedSecond })}>
                            <Icon
                                height={32}
                                width={32}
                                color={"primary"}
                                style={{ cursor: "pointer", padding: 0 }}
                                name={expandedSecond ? "ArrowDoubleUp" : "ArrowDoubleDown"} />
                            <div className={classes.divText}>
                                <Typography> {expandedSecond ? Config.lang("Thu_nho") : Config.lang("Mo_rong")}</Typography>
                            </div>
                        </span>
                    </div>
                </div>
                <Row className={"mgt25"}>
                    <Col xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
                        <Attachment
                            maxFile={5}
                            multiple={true}
                            disabled={disableAttachment}
                            ref={ref => this.attRef = ref}
                            data={this.getAttachment}
                            onChange={this.onChangeAttachments}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(
    connect(
        state => ({
            getCboDepartments: state.general.getCboDepartments,
            getCboTeam: state.general.getCboTeam,
            getLoadEducation: state.general.getLoadEducation,
            getCboProjects: state.general.getCboProjects,
            getCboWorks: state.general.getCboWorks,
            getCboDuty2: state.general.getCboDuty2,
            getLoadCurrency: state.general.getLoadCurrency,
            getLoadProfessionalLevel: state.general.getLoadProfessionalLevel,
            getLoadForm: state.W25F2002.getLoadForm,
            dataCboPlan: state.W25F2002.dataCboPlan,
            dataCboReason: state.W25F2002.dataCboReason,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W25F2002Actions: bindActionCreators(W25F2002Actions, dispatch)
        })
    ),
    withStyles(styles, { withTheme: true })
)(W25F2002);