/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/24/2020
 * @Example
 */

import React                         from "react";
import {bindActionCreators, compose} from "redux";
import {withStyles}       from "@material-ui/core";
import {connect}                     from "react-redux";
import * as generalActions           from "../../../../redux/general/general_actions";
import ActionToolbar                 from "../../../common/toolbar/action-toolbar";
import Config                        from "../../../../config";
import ButtonGeneral                 from "../../../common/button/button-general";
import GridContainer                 from "../../../grid-container/grid-container";
import {Column}                      from "devextreme-react/data-grid";
import IconButton                    from "@material-ui/core/IconButton";
import {Image, FormGroup, Row, Col}  from "react-bootstrap";
import Filter                        from "../../../filter/filter";
import {Combo}                       from "../../../common/form-material";
import DateBoxPicker                 from "../../../common/form-material/date-box";
import moment                        from "moment";
import {browserHistory}              from "react-router";
import UserImage                     from "../../../common/user/user-image";
import Status                        from "../../../common/status/status";
import _                             from "lodash";
import Icons                         from "../../../common/icons/";
import * as W25F2010Actions          from "../../../../redux/W2X/W25F2010/W25F2010_actions";
import * as W25F2015Actions          from "../../../../redux/W2X/W25F2015/W25F2015_actions";
import {LinearProgress}              from '@material-ui/core';
import GridActionBar                 from "../../../grid-container/grid-actionbar";
import UserName                      from "../../../common/user/user-name";
import imgDefault                    from "../../../../assets/images/W25F2010/defaultimg.png";
import PopoverAction                 from "../../../grid-container/popover-action";

import {Button}                      from "diginet-core-ui/components";
import { More }                from "diginet-core-ui/icons";
import { TabContainer, TabHeader, TabItem, TabPanel } from 'diginet-core-ui/components';

const styles = (theme) => ({
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000"
    },
    avatarCircle:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 46,
        height: 46,
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        fontSize: "1.2rem",
        fontWeight: 600,
        textTransform: "uppercase"
    },
    colorRed: {
        color: '#FF3D71'
    },
    colorOrange: {
        color: '#FFAA00'
    },
    colorGreen: {
        color: '#00C875'
    },
    colorBlue: {
        color: '#0095FF',
    },
    colorGray: {
        color: '#9597A1'
    },
    rootProgress: {
        height: 8,
        borderRadius: 4,
    },
    bgColorOrange: {
        backgroundColor: "#FFAA00"
    },
    bgColorGray: {
        backgroundColor: "#9597A1"
    },
    bgColorGreen: {
        backgroundColor: "#00C875"
    },
    bgColorRed: {
        backgroundColor: "#FF3D71"
    },
    textCenter : {
        textAlign: 'center',
        padding: '15px 0'
    },
    textNumber : {
        fontWeight: 700,
        fontSize: "14px"
    },
    styleStatus: {
        '& .MuiChip-root': {
            margin:0,
            padding:0,
            '& .MuiIcon-root': {
                margin:0
            }
        }
    },
    stylePadding: {
        padding: '0 0 8px 0',
    },
    dataGrid: {
        '& .dx-datagrid-rowsview': {
            '& .dx-datagrid-content td:not(:first-child)': {
                verticalAlign: "top!important",
            },
            '& .dx-datagrid-content td:nth-of-type(4)': {
                padding: 0
            }
        }
    },
});
class W25F2010 extends React.Component {
    constructor(props) {
        super(props);
        this.state   = {
            iPermission:       4,
            mode:              "add",
            typeAdd:           0,
            tabIndex:          2,
            CreateDate1:       null,
            CreateDate2:       null,
            ReceiveDateFrom1:  null,
            ReceiveDateFrom2:  null,
            ReceiveDateTo1:    null,
            ReceiveDateTo2:    null,
            showW09F2060Popup: false,
            dataGrid:          [],
            total:             0,
            loading:           {
                cboEmployees: false,
                cboProjects:  false,
            },
        };
        this.loading = {
            cboEmployees: false,
            cboProjects:  false,
        };
        this.dataPopover = null;
        this.filter  = {
            DutyID: "",
            RecpositionID: "",
            StatusID:      "",
            DepartmentID:  "",
            skip:          0,
            limit:         10
        };
    }

    handleChangeTab = (value) => {
        this.filter = { RecpositionID: "", StatusID: "", DepartmentID: "", skip: 0, limit: 10 };
        this.setState({ tabIndex: value, CreateDate1: null, CreateDate2: null, ReceiveDateFrom1: null, ReceiveDateFrom2: null,
            ReceiveDateTo1: null, ReceiveDateTo2: null}, () => {
            this.loadDataGrid()
        });
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W25F2010", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCboDepartment = () => {
        const param = {
            HostID: "",
            FormID: "W25F2010",
            Language: Config.language || "84",
            DivisionID: Config.getDivisionID(),
        };
        this.props.generalActions.getCboDepartments(param, (errors) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    };

    loadCboPosition = () => {
        this.props.generalActions.getCboDuty2(errors => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID }
        });
    };
    redirectScreen = (mode, e) => {
        const {dataPopover} = this;
        let screen = '';
        let data = null;
        switch (mode) {
            case "add":
                screen = "W25F2011";
                break;
            case "edit":
                screen = "W25F2011";
                data = dataPopover;
                break;
            case "viewInfo":
                screen = "W25F2015";
                data = e && e.data ? e.data : null;
                break;
            default:
                break;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: {
                mode: mode,
                ...data
            }
        });
    };


    renderEmpProfile = (e) => {
        const {data} = e;
        const { classes } = this.props;
        return (
            <div className={"display_row " + classes.divText}>
                <div className={"mgr10 display_row align-center valign-middle"}>
                    {
                        data && data.ImageURL ?
                            <Image style={{maxHeight:"100%",height:"100px",width:50,objectFit: "contain"}} src={data.ImageURL.indexOf('http') < 0 ? Config.getCDNPath() + data.ImageURL : data.ImageURL}/>
                            :
                            <Image style={{maxHeight:"100%",height:"100px",width:50,objectFit: "contain"}} src={imgDefault}/>
                    }

                </div>
                <div>
                    { data && data.RecInfoTitle &&
                    <div>
                                  <span style={{fontSize:16,fontWeight:500}} >
                                      { /* eslint-disable */}
                                      <a  className={classes.colorBlue} onClick={(evt) => {evt.preventDefault();this.redirectScreen("viewInfo", e)}}>{data.RecInfoTitle}</a>
                                      { /* eslint-enable */}
                                  </span>
                    </div>
                    }
                    { data && data.WorkingTypeName &&
                    <div className={classes.divDateText}>
                        {data.WorkingTypeName}
                    </div>
                    }
                    {data && (data.IsShowSalary === 1) &&
                    <div className={classes.divDateText}>
                        {data.SalaryFrom && `${new Intl.NumberFormat().format(data.SalaryFrom)} ${data.CurrencyID} - ${data.SalaryTo && new Intl.NumberFormat().format(data.SalaryTo)} ${data.CurrencyID}`}
                    </div>
                    }
                    {data &&
                    <div className={classes.divDateText}>
                        {Config.lang("Tao_boi")}:  <UserName data={data} keyExpr={"CreateUserID"} valueExpr={"UserID"}/>
                    </div>
                    }
                    {data &&
                    <div className={classes.divDateText}>
                        {`${data.ReceiveDateFrom && moment(data.ReceiveDateFrom).format("DD/MM/YYYY")} - ${ data.ReceiveDateTo && moment(data.ReceiveDateTo).format("DD/MM/YYYY")}`}
                    </div>
                    }

                </div>

            </div>
        );
    };

    renderStatus = e => {
        if(!e) return null;
        const { classes } = this.props;
        const { data } = e.row;
        let statusColor = '';
        switch (data.StatusID) {
            case "DRAFT":
                statusColor = "default";
                break;
            case "PUBLIC":
                statusColor = "success";
                break;
            case "INTERNAL":
                statusColor = "warning";
                break;
            case "CLOSE":
                statusColor = "danger";
                break;
            default:
                break;
        }
        const status = {
            AppStatusID: data.StatusID,
            AppStatusName: data.StatusName,
            AppStatusIcon: <Icons className={"fas fa-circle"} />,
            AppStatusColor: statusColor
        };
        return (
            <Status className={classes.styleStatus} data={status} />
        );
    };

    onAdd = () => {
        this.redirectScreen("add");
    };

    onAction = (e,data) => {
        const target = e.currentTarget;
        this.dataPopover = data;
        if (this.popover) this.popover.instance.show(target);
    };

    loadInfoEmployment =  (data, cb) => {
        const params = {
            RecInfoID: data.RecInfoID,
            Language: Config.language || "84",
        };
        this.props.w25F2015Actions.getLoadInfoEmployment(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            cb && data && cb(data);
        });
    };

    renderAction = e => {
        const {iPermission} = this.state;
        const {data} = e;
        return (
            <>
                <GridActionBar>
                    <IconButton disabled={!iPermission > 1} aria-label="view" size="medium" style={{ padding: 3 }} onClick={(e) => this.onAction(e,data)}>
                        {/*<Icons name={"biggroup"}/>*/}
                        <More width={18}/>
                    </IconButton>
                </GridActionBar>

            </>
        )
    };

    filterChange = (key, data) => {
        const value = _.get(data,"value", _.get(data,"target.value", ""));
        const keyState = ["CreateDate1", "CreateDate2", "ReceiveDateFrom1", "ReceiveDateFrom2", "ReceiveDateTo1", "ReceiveDateTo2"];
        if(keyState.includes(key)){
            this.setState({
                [key]: value
            });
            return;
        }
        this.filter[key] = value;
    };

    renderFilter = () => {
        const {
                  getCboDuty2,
                  getCboDepartments,
                  getCboStatusNews,
                  classes
              } = this.props;
        const { DutyID, DepartmentID, StatusID } = this.filter;
        const { CreateDate1, CreateDate2, ReceiveDateFrom1, ReceiveDateFrom2, ReceiveDateTo1, ReceiveDateTo2 } = this.state;
        return (
            <Filter
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Vi_tri_tuyen_dung")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDuty2)}
                                            valueExpr={"DutyID"}
                                            displayExpr={"DutyName"}
                                            onValueChanged={(e) => this.filterChange("DutyID", e)}
                                            showClearButton={true}
                                            value={DutyID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Trang_thai_tin")}
                                            dataSource={getCboStatusNews}
                                            displayExpr={"Name"}
                                            valueExpr={"ID"}
                                            onValueChanged={e => this.filterChange("StatusID", e)}
                                            showClearButton={true}
                                            value={StatusID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Phong_ban")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                                            valueExpr={"DepartmentID"}
                                            displayExpr={"DepartmentName"}
                                            onValueChanged={(e) => this.filterChange("DepartmentID", e)}
                                            showClearButton={true}
                                            value={DepartmentID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={2} lg={2}>
                                        <div className={classes.divText +' '+ classes.textCenter}>
                                            {Config.lang("Ngay_tao")}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={5} lg={5}>
                                        <div style={{ display: "flex", alignItems: "center" }}>

                                            <DateBoxPicker
                                                dateBoxProps={{ max: CreateDate2 ? CreateDate2 :  moment() }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("CreateDate1", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={CreateDate1 || ""}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={5} lg={5}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <DateBoxPicker
                                                dateBoxProps={{ min: CreateDate1, max: moment() }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("CreateDate2", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={CreateDate2 || ""}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={2} lg={2}>
                                        <div className={classes.divText +' '+ classes.textCenter}>
                                            {Config.lang("Ngay_bat_dau")}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={5} lg={5}>
                                        <div style={{ display: "flex", alignItems: "center" }}>

                                            <DateBoxPicker
                                                dateBoxProps={{ max: ReceiveDateFrom2 ? ReceiveDateFrom2 :  moment() }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("ReceiveDateFrom1", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={ReceiveDateFrom1 || ""}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={5} lg={5}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <DateBoxPicker
                                                dateBoxProps={{ min: ReceiveDateFrom1, max: moment() }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("ReceiveDateFrom2", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={ReceiveDateFrom2 || ""}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={2} lg={2}>
                                        <div className={classes.divText +' '+ classes.textCenter}>
                                            {Config.lang("Ngay_ket_thuc")}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={5} lg={5}>
                                        <div style={{ display: "flex", alignItems: "center" }}>

                                            <DateBoxPicker
                                                dateBoxProps={{ max: ReceiveDateTo2 ? ReceiveDateTo2 :  moment() }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("ReceiveDateTo1", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={ReceiveDateTo1 || ""}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={5} lg={5}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <DateBoxPicker
                                                dateBoxProps={{ min: ReceiveDateTo1, max: moment() }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("ReceiveDateTo2", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={ReceiveDateTo2 || ""}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "7px 0",
                                }}
                            >
                                <ButtonGeneral
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    componentDidMount = async() => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.loadCboDepartment();
        this.loadCboPosition();
        this.loadDataGrid();
        this.loadCboStatusNews();
        // this.loadDataLink();
    };


    loadDataGrid = () => {
        const {  CreateDate1, CreateDate2, ReceiveDateFrom1, ReceiveDateFrom2, ReceiveDateTo1, ReceiveDateTo2, tabIndex } = this.state;
        const { DutyID, DepartmentID,limit, skip, StatusID} = this.filter;
        let idTab = "";
        switch (tabIndex) {
            case 0:
                idTab = 2;
                break;
            case 1:
                idTab = 1;
                break;
            case 2:
                idTab = 0;
                break;
            default:
                break;
        }
        const param = {
            FormID: "W25F2010",
            Language: Config.language || 84,
            RecpositionID: DutyID ? DutyID : '',
            DepartmentID: DepartmentID ? DepartmentID : "",
            CreateDate1: CreateDate1 ? moment(CreateDate1).format("YYYY-MM-DD") : null,
            CreateDate2: CreateDate2 ? moment(CreateDate2).format("YYYY-MM-DD") : null,
            ReceiveDateFrom1: ReceiveDateFrom1 ? moment(ReceiveDateFrom1).format("YYYY-MM-DD") : null,
            ReceiveDateFrom2: ReceiveDateFrom2 ? moment(ReceiveDateFrom2).format("YYYY-MM-DD") : null,
            ReceiveDateTo1: ReceiveDateTo1 ? moment(ReceiveDateTo1).format("YYYY-MM-DD") : null,
            ReceiveDateTo2: ReceiveDateTo2 ? moment(ReceiveDateTo2).format("YYYY-MM-DD") : null,
            DivisionID: Config.getDivisionID(),
            IsUser: idTab || (idTab === 0) ? idTab : 2,
            StatusID: StatusID ? StatusID : "",
            skip: skip,
            limit: limit
        };
        this.setState({ gridLoading: true });
        this.props.w25F2010Actions.getLoadForm(param, (error,data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({
                dataGrid: data && data.rows ? data.rows : [],
                total: data && data.total ? data.total : 0,
            }, () => {
                this.setState({ gridLoading: false });
            });
        });
    };

    onDelete = () => {
        const { RecInfoID } = this.dataPopover;
        const params = {
            FieldName: "W25F2010",
            values: "",
            Key01ID: RecInfoID || "",
            Key02ID: "",
            Mode: 0,
            Key03ID: "",
            Language: Config.language || 84,
            FormID: "W25F2010",
            EmployeeID: "",
            TransID: RecInfoID || ""
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
                this.props.w25F2010Actions.deleteData(params, (error, data) => {
                    if(data) {
                        if(data.Status === 1) {
                            let message = data.Message;
                            Config.popup.show("INFO", message);
                            return false;
                        }
                    }
                    if (error) {
                        let message = error.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("ERROR", message);
                        return false;
                    } else {
                        Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                        this.loadDataGrid();
                    }
                });
            }
        );
    };

    loadCboStatusNews = () => {
        const params = {
            FormID: "W25F2010",
            Key01ID: "",
            Key02ID: "",
            Key03ID: "",
            Language: Config.language || 84,

        };
        this.props.w25F2010Actions.getCboStatusNew(params, error => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    renderSLA = (e) => {
        const { classes } = this.props;
        const {data} = e;
        let receiveDateFrom = moment(data.ReceiveDateFrom).format("YYYY-MM-DD");
        let presentDay = moment().format("YYYY-MM-DD");
        // let receiveDateTo = moment(data.ReceiveDateTo).format("YYYY-MM-DD");
        let deadline = data?.Deadline && moment(data.Deadline).format("YYYY-MM-DD");

        let classProgressBar = "";
        let valuePercent = 100;
        if(presentDay  <= receiveDateFrom ||  data.Deadline === null ) {
            classProgressBar = classes.bgColorGray;
        }

        if(receiveDateFrom  < presentDay && presentDay < deadline ) {
            let totalDay = moment(deadline).diff(moment(receiveDateFrom), 'days');
            let remainday = moment(deadline).diff(moment(presentDay), 'days');
            valuePercent =  (totalDay - remainday) * 100 / totalDay;
            classProgressBar = classes.bgColorGreen;
        } else {
            classProgressBar = classes.bgColorGray;
        }

        if((presentDay >= deadline) && (data.NumHired >= data.NumPerson) ) {
            classProgressBar = classes.bgColorGreen;
        }

        if((presentDay >= deadline) && (data.NumHired < data.NumPerson) ) {
            classProgressBar = classes.bgColorGray;
        }

        return (
            <div className={classes.divText}>
                <div className={classes.divDateText + " " + classes.stylePadding}>
                    {Config.lang("So_luong")}: <span className={classes.textNumber+ " " +classes.colorBlue}>{data.NumPerson}</span>
                </div>
                <div className={Number(data.IsExpired) === 1 ? classes.stylePadding : ""}>
                    <LinearProgress classes={{root: classes.bgColorGray +' '+ classes.rootProgress,barColorPrimary: classProgressBar}}  variant="determinate" value={valuePercent}/>
                </div>
                <div className={classes.stylePadding}>
                    {
                        (presentDay >= deadline) && !isNaN(data.IsExpired) && Number(data.IsExpired) === 1 && (
                            <span className={classes.colorRed} style={{fontWeight:700}}>{Config.lang("Qua_han")}</span>
                        )
                    }

                </div>
                {  data && data.Deadline &&
                <div className={classes.divDateText}>
                    {Config.lang("Deadline")}: {moment(data.Deadline).format("DD/MM/YYYY")}
                </div>
                }

            </div>
        );
    };

    renderNumReceiveCV = (e) => {
        const { classes } = this.props;
        const {data} = e;
        let percentAmount = data.NumHired * 100 / data.NumReceiveCV;
        percentAmount = percentAmount < 0 ? 0 : (percentAmount > 100 ? 100 : percentAmount);
        return (
            <div className={classes.divText}>
                { data &&  !isNaN(data.NumReceiveCV)  &&
                <div className={classes.divDateText + " " +classes.stylePadding}>
                    <span className={classes.textNumber + " " + classes.colorBlue}>{data.NumReceiveCV}</span> :  {Config.lang("Ung_vien")}
                </div>
                }

                <div className={classes.stylePadding}>
                    <LinearProgress classes={{root: classes.bgColorGray +' '+ classes.rootProgress,barColorPrimary:data.NumHired > 0 ? classes.bgColorGreen  : classes.bgColorGray}}  variant="determinate" value={percentAmount}/>
                </div>
                {  data && !isNaN(data.NumHired)  &&
                <div className={classes.divDateText}>
                    {Config.lang(Config.lang("Hired"))}: <span className={(data.NumHired > 0 ? classes.colorGreen  : "") + " " + classes.textNumber}>{data.NumHired}</span>
                </div>
                }


            </div>
        );
    };

    renderNumInterview = (e) => {
        const { classes } = this.props;
        const {data} = e;
        let Started = data && (data.NumStarted === 0) && (data.NumNew === 0) && (data.NumCancel === 0) ? 0 :  data.NumStarted / (data.NumStarted + data.NumNew + data.NumCancel);
        let Scheduled = data && (data.NumStarted === 0) && (data.NumNew === 0) && (data.NumCancel === 0) ? 0 :  data.NumNew / (data.NumStarted + data.NumNew + data.NumCancel);
        let Cancel = data && (data.NumStarted === 0) && (data.NumNew === 0) && (data.NumCancel === 0) ? 0 : data.NumCancel / (data.NumStarted + data.NumNew + data.NumCancel);
        let percentStart =  Started * 100;
        let percentScheduled =  Scheduled * 100;
        let percentCancel =  Cancel * 100;
        return (
            <div className={classes.divText}>
                <div className={classes.divDateText + " " + classes.stylePadding}>
                    <span className={classes.textNumber + " " +classes.colorBlue}>{data.NumInterview}</span> :  {Config.lang("Phong_van")}
                </div>
                <div className={classes.stylePadding}>
                    <LinearProgress classes={{root: ((percentStart === 0) && (percentScheduled === 0) && (percentCancel === 0)  ? classes.bgColorGray : classes.bgColorRed) +' '+ classes.rootProgress,barColorPrimary: classes.bgColorGreen,bar2Buffer: classes.bgColorOrange, dashed: 'hide'}}  variant={"buffer"} valueBuffer={percentScheduled+percentStart} value={percentStart}/>
                </div>
                { data && !isNaN(data.NumStarted)  &&
                <div className={classes.divDateText}>
                    {Config.lang("Started")}: <span className={(data.NumStarted > 0 ? classes.colorGreen   : classes.colorGray) + " " + classes.textNumber}>{data.NumStarted}</span>
                </div>
                }
                { data && !isNaN(data.NumNew)  &&
                <div className={classes.divDateText}>
                    {Config.lang("Scheduled")}: <span className={(data.NumNew > 0 ? classes.colorOrange  : classes.colorGray) + " " + classes.textNumber }>{data.NumNew}</span>
                </div>
                }
                { data && !isNaN(data.NumCancel)  &&
                <div className={classes.divDateText}>
                    {"Cancel"}: <span className={(data.NumCancel > 0 ? classes.colorRed  : classes.colorGray) + " " + classes.textNumber }>{data.NumCancel}</span>
                </div>
                }
            </div>
        );
    };

    renderUserImage = (e) => {
        const { data } = e;
        let listMember = data.ListMember.split(",");
        listMember = listMember.map(item => item.trim());
        const listUsers = Config.getListUser(listMember, "UserID");
        return (
            <div className={"display_row align-left"}>
                {
                    listUsers.map((data,index) => {
                        return (
                            <UserImage width={"24px"} height={"24px"} allowHover={true} key={index}  data={data}/>

                        )
                    })
                }
            </div>
        )

    };

    renderCreateUserImage = (e) => {
        const { data } = e;
        return (
            <UserImage  width={"24px"} height={"24px"} data={data} keyExpr={"CreateUserID"} valueExpr={"UserID"} />
        )
    };

    render() {
        const { classes } = this.props;
        const { iPermission, gridLoading, dataGrid, total, tabIndex } = this.state;
        if (iPermission <= 0) return null;
        const { skip, limit } = this.filter;
        const dataTab = [Config.lang("Toi_quan_ly"),Config.lang("Toi_da_tao"),Config.lang("Tat_ca_tin")];
        return (
            <>
                <PopoverAction
                    reference={ref => this.popover = ref}
                    position={"right"}
                    maxWidth={300}
                    shading={false}
                    deferRendering={false}
                >
                    <div style={{ padding: 5 }}>
                        <ButtonGeneral
                            disabled={!iPermission > 2}
                            variant={"text"}
                            aria-label={Config.lang("Chinh_sua_tin_tuyen_dung")}
                            name={Config.lang("Chinh_sua_tin_tuyen_dung")}
                            style={{display:'block',width:'100%',textAlign: 'left'}}
                            onClick={() => {
                                this.redirectScreen("edit");
                            }}
                        />
                        <ButtonGeneral
                            variant={"text"}
                            aria-label={Config.lang("Xem_tren_website_tuyen_dung")}
                            name={Config.lang("Xem_tren_website_tuyen_dung")}
                            style={{display:'block',width:'100%',textAlign: 'left'}}
                            onClick={() => {
                                //check Slug, if null then show message
                                if(!this.dataPopover?.Slug) {
                                    Config.popup.show("INFO",Config.lang("Tin_chua_duoc_dang_cong_khai"));
                                    return;
                                }

                                //check Slug, if exits then open new tab browser
                                let url = Config.getSetting("RECRUITMENT_URL");
                                if(url[url.length-1] === "/") url = url.slice(0,-1);
                                url += "/Recruiment-detail/" + this.dataPopover.Slug;

                                window.open(url);
                            }}
                        />
                        <ButtonGeneral
                            disabled={!iPermission > 3}
                            variant={"text"}
                            aria-label={Config.lang("Xoa_tin_tuyen_dung")}
                            name={Config.lang("Xoa_tin_tuyen_dung")}
                            style={{display:'block',width:'100%',textAlign: 'left'}}
                            onClick={() => {
                                this.onDelete();
                            }}
                        />
                    </div>
                </PopoverAction>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("Danh_sach_tin_tuyen_dung")}>
                    <Button
                        disabled={!iPermission > 1}
                        color={'primary'}
                        viewType={'filled'}
                        text={Config.lang("Them")}
                        startIcon={'Add'}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <TabContainer
                    color="#7F828E"
                    id="tab-Container"
                    value={tabIndex}
                >
                    <TabHeader
                        style={{
                            justifyContent: 'space-between'
                        }}
                    >
                        {dataTab.map((item, idx)=>
                            <TabItem
                                index={idx}
                                label={item}
                                onClick={()=>this.handleChangeTab(idx)}
                            />
                        )}
                    </TabHeader>
                    {dataTab.map((item, idx)=>
                        <TabPanel index={idx}/>
                    )}
                </TabContainer>
                <GridContainer
                    style={{ border: "none" }}
                    dataSource={dataGrid}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    noDataText={Config.lang("No_data")}
                    onCellClick={this.onDetail}
                    loading={gridLoading}
                    rowAlternationEnabled={false}
                    elementAttr={{
                        class: classes.dataGrid
                    }}
                    hoverStateEnabled={true}
                    totalItems={total}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid() - 42}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column
                        fixed={true}
                        fixedPosition={"left"}
                        headerCellRender={() => {
                            return (
                                <Icons name={"biggroup"}/>
                            )
                        }}
                        cellRender={this.renderAction}
                    />
                    <Column  caption={Config.lang("Tin_tuyen_dung")}
                             alignment={"left"}
                             cellRender={this.renderEmpProfile}/>
                    <Column  caption={Config.lang("Phong_ban")}
                             dataField={"DepartmentName"}
                             alignment={"left"}
                             width={150} />
                    <Column  caption={Config.lang("Trang_thai")}
                             alignment={"left"}
                             width={100}
                             cellRender={this.renderStatus}/>
                    <Column  caption={Config.lang("SLA")}
                             alignment={"left"}
                             cellRender={this.renderSLA}
                             width={200}/>
                    <Column  caption={Config.lang("Vi_tri")}
                             dataField={"DutyName"}
                             alignment={"left"}/>
                    <Column  caption={Config.lang("Thong_ke_ung_vien")}
                             alignment={"left"}
                             cellRender={this.renderNumReceiveCV}
                             width={200} />
                    <Column  caption={Config.lang("Thong_ke_phong_van")}
                             alignment={"left"}
                             cellRender={this.renderNumInterview}
                             width={200} />
                    <Column  caption={Config.lang("Nguoi_quan_ly")}
                             alignment={"left"}
                             cellRender={this.renderUserImage}
                             width={200} />
                    <Column  caption={Config.lang("Nguoi_tao_tin")}
                             alignment={"left"}
                             cellRender={this.renderCreateUserImage}
                             width={200} />
                    <Column  caption={Config.lang("Ngay_tao")}
                             dataType={"date"}
                             format={"dd/MM/yyyy"}
                             dataField={"CreateDate"}
                             alignment={"left"}
                    />
                    <Column  caption={Config.lang("Ngay_bat_dau")}
                             alignment={"center"}
                             dataType={"date"}
                             dataField={"ReceiveDateFrom"}
                             format={"dd/MM/yyyy"}
                    />
                    <Column  caption={Config.lang("Ngay_ket_thuc")}
                             alignment={"center"}
                             dataType={"date"}
                             dataField={"ReceiveDateTo"}
                             format={"dd/MM/yyyy"}
                    />
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboStatusNews: state.W25F2010.getCboStatusNews,
            // getLink: state.W25F2010.getLink,
            getCboDuty2: state.general.getCboDuty2,
            getCboDepartments: state.general.getCboDepartments,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w25F2010Actions: bindActionCreators(W25F2010Actions, dispatch),
            w25F2015Actions: bindActionCreators(W25F2015Actions, dispatch)
        })
    ),
    withStyles(styles, { withTheme: true})
)(W25F2010);
