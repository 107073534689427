/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/29/2020
 * @Example 
 */

import { Chip, Label, TextInput } from 'diginet-core-ui/components';
import * as _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import Config from "../../../../config";

class W25F2001Education extends React.Component {

    parseCommaToLine = (stringValue = "") => {
        if (_.isEmpty(stringValue)) return;
        return stringValue.replace(",", " \n ");
    }

    renderChip = (stringValue = "") => {
        if (!_.isString(stringValue) || _.isEmpty(stringValue)) return;
        const parseStringToArr = stringValue.split(",");
        return (
            parseStringToArr.map(label =>
                <Chip
                    style={{ margin: '5px 5px 0 0' }}
                    color={"primary"}
                    label={label}
                />)
        )
    }

    render() {
        const { data: { Experienced = "", EducationLevelName = "", Capability = "", ProfessionalLevelName = "",
            ComputerSkills = "", EnglishLevel = "", OtherRequest = "" } } = this.props;

        return (
            <div style={{ width: "100%" }}>
                {!_.isEmpty(Experienced) &&
                    <FormGroup className={"mgb0"}>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Kinh_nghiem")}
                                    value={Experienced}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                }
                <FormGroup className={"mgb20"}>
                    <Row>
                        {!_.isEmpty(EducationLevelName) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Label style={{ display: 'block' }} children={Config.lang("Trinh_do_hoc_van")} />
                                <div className={"mgt5"}> {this.renderChip(EducationLevelName)} </div>
                            </Col>
                        }
                        {!_.isEmpty(ProfessionalLevelName) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Label style={{ display: 'block' }} children={Config.lang("Trinh_do_chuyen_mon")} />
                                <div className={"mgt5"}> {this.renderChip(ProfessionalLevelName)} </div>
                            </Col>
                        }
                    </Row>
                </FormGroup>
                {!_.isEmpty(Capability) &&
                    <FormGroup className={"mgb0"}>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Ky_nang")}
                                    value={Capability}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                }
                <FormGroup className={"mgb0"}>
                    <Row>
                        {!_.isEmpty(ComputerSkills) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Trinh_do_tin_hoc")}
                                    value={ComputerSkills}
                                />
                            </Col>
                        }
                        {!_.isEmpty(EnglishLevel) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Trinh_do_ngoai_ngu")}
                                    value={EnglishLevel}
                                />
                            </Col>
                        }
                    </Row>
                </FormGroup>
                {!_.isEmpty(OtherRequest) &&
                    <FormGroup className={"mgb0"}>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Yeu_cau_khac")}
                                    value={OtherRequest}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                }
            </div>
        );
    }

}

W25F2001Education.propTypes = {
    data: PropTypes.object
};

export default W25F2001Education;
