import React, {Component} from 'react';
import {
    Box,
    InputAdornment,
    TextField,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Config from "../../../../config";
import {FormGroup, Image} from "react-bootstrap";
import {Combo} from "../../../common/form-material";
import _ from "lodash";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import {Editor} from "@tinymce/tinymce-react";
import moment from "moment";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as W25F2033Actions from "../../../../redux/W2X/W25F2033/W25F2033_actions";
import ReactLoading from "react-loading";
import Api from '../../../../services/api';
import PropTypes from "prop-types";
import ButtonGeneral from "../../../common/button/button-general";

const styles = theme => ({
    dashedBox: {
        padding: "21px 0",
        borderTopWidth: 3,
        borderTopColor: "rgba(150, 152, 162, 0.45)",
        borderTop: "dashed",
    },
    boxShadowCard: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
    },
    flexDRow: {
        display: "flex",
        alignItems: "center",
    },
    expansionSummaryRoot: {
        minHeight: "45px !important",
        "& .MuiTypography-h6": {
            color: theme.palette.text.primary,
            fontSize: "1.2rem !important",
            fontWeight: "600 !important",
        },
    },
    expansionSummaryContent: {
        margin: "0 !important",
        "&.withSubTitle": {
            flexDirection: "column",
            padding: "10px 0",
        },
    },
    expansionDetailsRoot: {
        height: "100%",
        display: "block !important",
    },
    subText: {
        color: theme.palette.text.subtext,
        fontWeight: 400,
    },
    avatarCandidate: {
        width: 45,
        height: 45,
        color: "#FFF",
        backgroundColor: theme.palette.primary.main,
        marginRight: 8
    },
    editor: {
        "& .tox-tinymce": {
            borderRadius: 4,
            "& :focus": {
                borderColor: theme.palette.primary.main
            }
        }
    },
    titleMail: {
        [theme.breakpoints.up("md")]: {
            width: "10%",
        },
        [theme.breakpoints.down("md")]: {
            width: "30%",
        },
    },
    contentTitleMail: {
        borderLeft: "solid",
        borderWidth: 1,
        borderColor: "#E4E9F2",
        paddingLeft: 5,
        [theme.breakpoints.up("md")]: {
            width: "90%",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    fillLoading: {
        fill: theme.palette.primary.main + "!important",
    },
    colorPrimary: {
        color: theme.palette.primary.main
    }
})


class CandidateMailExchangeCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateMailID: "",
            Content: "",
            TitleMail: "",
            isLoadingDataMail: false,
            isLoadingMailTemplate: false,
            isSending: false,
        };
        this.params = {
            moduleId: "W25",
            transactionId: "INTERVIEW",
            priority: "normal",
        }
    }

    getKeyParam = () => ({
        key01: _.get(this.props, "dataForm.CandidateID", "") || "",
        key02: _.get(this.props, "dataForm.RecInfoID", "") || "",
        key03: _.get(this.props, "dataForm.InterviewFileID", "") || "",
    })

    loadMailData = () => {
        this.setState({isLoadingDataMail: true});
        this.props.w25f2033Actions.loadMailData(this.getKeyParam(), err => {
            this.setState({isLoadingDataMail: false})
            if (err) Config.popup.show("ERROR", err);
        })
    };

    loadMailTemplate = () => {
        this.setState({isLoadingMailTemplate: true});
        this.props.w25f2033Actions.getMailTemplate((err) => {
            this.setState({isLoadingMailTemplate: false});
            if (err) Config.popup.show("ERROR", err);
        })
    };

    formatDate = (date, format = "DD/MM/YYYY") => {
        const fmDate = moment.utc(date);
        if (!fmDate.isValid()) return "";
        return fmDate.format(format);
    }

    onSendNewMail = () => {
        const {classes} = this.props;
        const {Content, TitleMail, templateMailID} = this.state;
        const candidateEmail = _.get(this.props, "dataForm.CandidateEmail", "");
        const params = {
            to: [candidateEmail],
            content: Content,
            subject: TitleMail,
            params: templateMailID === "DH02" ? JSON.stringify([
                {
                    key: "InterviewType",
                    type: "VARCHAR",
                    value: "Youngblood"
                },
                {
                    key: "CandidateName",
                    type: "VARCHAR",
                    value: "Keanu Reeves"
                },
                {
                    key: "Lastname",
                    type: "VARCHAR",
                    value: "Keanu Reeves"
                },
                {
                    key: "Prefix",
                    type: "VARCHAR",
                    value: "Replicas"
                },
                {
                    key: "EmailSystem",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "RecpositionName",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "Joblink",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "JobUpdateLink",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "DepartmentName",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "CompanyAddress",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "UserFirstname",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "UserFullname",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "UserEmail",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "UserPhone",
                    type: "VARCHAR",
                    value: "911"
                },
                {
                    key: "CompanyName",
                    type: "VARCHAR",
                    value: "Already Gone"
                }
            ]) : JSON.stringify([]),
            ...this.getKeyParam(),
            ...this.params
        };
        Config.popup.show("YES_NO",`${Config.lang("Ban_co_muon_gui_den_mail_nay")} <b class=${classes.colorPrimary}>${candidateEmail}</b>`, async () => {
            this.setState({isSending: true});
            const res = await Api.post("/mail/send", params);
            this.setState({isSending: false});
            const messageErr = _.get(res, "message", "");
            const data = _.get(res, "data", {})
            if (messageErr) {
                Config.popup.show("ERROR", messageErr);
                return false;
            }
            if (!_.isEmpty(data)) {
                Config.notify.show("success", Config.lang("Gui_thanh_cong"), 2000);
                this.setState({
                    templateMailID: "",
                    Content: "",
                    TitleMail: ""
                });
                this.loadMailData();
            }
        })
    };

    onReplyMail = () => {
        const {classes} = this.props;
        const {Content, TitleMail, templateMailID} = this.state;
        const candidateEmail = _.get(this.props, "dataForm.CandidateEmail", "");
        const params = {
            to: [candidateEmail],
            content: Content,
            subject: TitleMail,
            params: templateMailID === "DH02" ? JSON.stringify([
                {
                    key: "InterviewType",
                    type: "VARCHAR",
                    value: "Youngblood"
                },
                {
                    key: "CandidateName",
                    type: "VARCHAR",
                    value: "Keanu Reeves"
                },
                {
                    key: "Lastname",
                    type: "VARCHAR",
                    value: "Keanu Reeves"
                },
                {
                    key: "Prefix",
                    type: "VARCHAR",
                    value: "Replicas"
                },
                {
                    key: "EmailSystem",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "RecpositionName",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "Joblink",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "JobUpdateLink",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "DepartmentName",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "CompanyAddress",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "UserFirstname",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "UserFullname",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "UserEmail",
                    type: "VARCHAR",
                    value: "The Watcher"
                },
                {
                    key: "UserPhone",
                    type: "VARCHAR",
                    value: "911"
                },
                {
                    key: "CompanyName",
                    type: "VARCHAR",
                    value: "Already Gone"
                }
            ]) : JSON.stringify([]),
            ...this.getKeyParam(),
            ...this.params
        }
        Config.popup.show("YES_NO", `${Config.lang("Ban_co_muon_tra_loi_den_mail_nay")} <b class=${classes.colorPrimary}>${candidateEmail}</b>`, async () => {
                this.setState({isSending: true});
                const res = await Api.post("/mail/reply", params);
                this.setState({isSending: false});
                const messageErr = _.get(res, "message", "");
                const data = _.get(res, "data", {})
                if (messageErr) {
                    Config.popup.show("ERROR", messageErr);
                    return false;
                }
                if (!_.isEmpty(data)) {
                    Config.notify.show("success", Config.lang("Gui_thanh_cong"), 2000);
                    this.setState({
                        templateMailID: "",
                        Content: "",
                        TitleMail: ""
                    });
                    this.loadMailData();
                }
            }
        )
    }

    onChangeTemplateMail = (e) => {
        const {getMailTemplate} = this.props;
        const templateMailID = _.get(e, "value", "");
        const dataMailTemp = _.get(_.filter(getMailTemplate, item => item.TemplateMailID === templateMailID), "[0]", {});
        this.setState({
            templateMailID,
            TitleMail: _.get(dataMailTemp, "TitleMail", ""),
            Content: _.get(dataMailTemp, "ContentMail", ""),
        })
    }

    onEditorChange = (value, type) => {
        this.setState({
            [type]: value
        })
    };

    renderDataMail = () => {
        const {classes, loadMailData, dataForm} = this.props;
        const candidateName = _.get(dataForm, "CandidateName", "");
        const candidatePictureURL = _.get(dataForm, "CandidatePictureURL", "");
        const getLinkCandidatePictureURL = candidatePictureURL.indexOf('http') < 0 ? Config.getCDNPath() + candidatePictureURL : candidatePictureURL;
        return (_.map(_.get(loadMailData, "rows", []), (item, i) => {
            const emailType = _.toUpper(_.get(item, "emailType", ""));
            const isRecruiter = emailType === "SEND" || emailType === "REPLY";
            return (
                <Box key={i} className={i > 0 ? classes.dashedBox : ""}>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            {
                                isRecruiter ?
                                    <UserImage keyExpr="createUserId"
                                               valueExpr="UserID"
                                               allowHover={false}
                                               data={item}
                                               width={45}
                                               height={45}/>
                                    :
                                    <UserImage
                                        src={getLinkCandidatePictureURL}
                                        width={45}
                                        height={45}/>
                            }
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                isRecruiter ?
                                    <UserName keyExpr="createUserId"
                                              valueExpr="UserID"
                                              data={item}
                                              allowHover={false}
                                              style={{fontWeight: 500, fontSize: "1.25rem"}}
                                    />
                                    :
                                    <Typography variant="h6">
                                        {candidateName}
                                    </Typography>
                            }
                            secondary={
                                <Typography className={classes.flexDRow}
                                            component="div">
                                    <Image
                                        style={{marginRight: 10}}
                                        src={require("../../../../assets/images/icon-calendar.svg")}
                                    />
                                    <Typography className={classes.subText}
                                                variant="subtitle1">
                                        {this.formatDate(_.get(item, "updatedAt", ""), "DD/MM/YYYY kk:mm:ss")}
                                    </Typography>
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={
                                <Typography style={{marginBottom: 10}} className={classes.flexDRow}
                                            component="div">
                                    <Image
                                        style={{marginRight: 10}}
                                        src={emailType === "RECIEVE" ? require("../../../../assets/images/reply-icon.svg") : emailType === "SEND" ? require("../../../../assets/images/share-icon.svg") : ""}
                                    />
                                    <Typography variant="h6">{_.get(item, "subject", "")}</Typography>
                                </Typography>
                            }
                            secondary={
                                <Typography component={"div"} variant="body1"
                                            dangerouslySetInnerHTML={{__html: _.get(item, "content", "")}}/>
                            }
                        />
                    </ListItem>
                </Box>)
        }))
    };

    renderTemplateMail = () => {
        const {classes, loadMailData, loadInfoCandidate, isViewOnly} = this.props;
        const {TitleMail, Content, isSending} = this.state;
        //const candidateName = _.get(dataForm, "CandidateName", "");
        // const spCandidateName = _.split(candidateName, " ");
        //const getMailTemplateByID = _.get(_.filter(getMailTemplate, (item) => item.TemplateMailID === templateMailID), "[0]", []);
        // let contentMail = _.get(getMailTemplateByID, "ContentMail", "");
        // let tempContentMail = "";
        // const arrDataFieldTemplateMail = _.filter([
        //     {
        //         fieldName: "Fullname",
        //         value: candidateName
        //     },
        //     {
        //         fieldName: "Firstname",
        //         value: _.toString(_.slice(spCandidateName, -1))
        //     },
        //     {
        //         fieldName: "Lastname",
        //         value: _.toString(_.slice(spCandidateName, 0, 1))
        //     },
        //     {
        //         fieldName: "CompanyName",
        //         value: _.get(dataForm, "CompanyName", "")
        //     },
        //     {
        //         fieldName: "Prefix",
        //         value: _.get(dataForm, "Prefix", "")
        //     },
        //     {
        //         fieldName: "EmailSystem",
        //         value: _.get(dataForm, "EmailSystem", "")
        //     },
        //     {
        //         fieldName: "RecpositionName",
        //         value: _.get(dataForm, "RecpositionName", "")
        //     },
        //     {
        //         fieldName: "Joblink",
        //         value: _.get(dataForm, "Joblink", "")
        //     },
        //     {
        //         fieldName: "JobUpdateLink",
        //         value: _.get(dataForm, "JobUpdateLink", "")
        //     },
        //     {
        //         fieldName: "DepartmentName",
        //         value: _.get(dataForm, "DepartmentName", "")
        //     },
        //     {
        //         fieldName: "CompanyAddress",
        //         value: _.get(dataForm, "CompanyAddress", "")
        //     },
        //     {
        //         fieldName: "UserFirstname",
        //         value: _.get(dataForm, "UserFirstname", "")
        //     },
        //     {
        //         fieldName: "UserFullname",
        //         value: _.get(dataForm, "UserFullname", "")
        //     },
        //     {
        //         fieldName: "UserEmail",
        //         value: _.get(dataForm, "UserEmail", "")
        //     },
        //     {
        //         fieldName: "UserPhone",
        //         value: _.get(dataForm, "UserPhone", "")
        //     }
        // ], item => Boolean(item.value));
        // const convertContentMail = _.reduce(arrDataFieldTemplateMail, (acc, itemCurrent) => {
        //     if (tempContentMail === "") tempContentMail = contentMail;
        //     acc = _.replace(tempContentMail, `{${itemCurrent.fieldName}}`, itemCurrent.value);
        //     tempContentMail = acc;
        //     return acc;
        // }, "");
        const hasMailData = _.get(loadMailData, "rows", []).length;
        const onSendMail = hasMailData > 0 ? this.onReplyMail : this.onSendNewMail;

        const disableBtnSend = _.isEmpty(TitleMail) || _.isEmpty(Content) || !!_.get(loadInfoCandidate,"[0]IsMoved", _.get(loadInfoCandidate,"IsMoved", 0));
        const isEmployee = !!_.get(loadInfoCandidate,"[0]IsEmployee", _.get(loadInfoCandidate,"IsEmployee", 0));
        const ApproveLevel = loadInfoCandidate[0]?.ApproveLevel || 0;
        const ApprovalStatus = loadInfoCandidate[0]?.ApprovalStatus || 0;
        return (
            <>
                <FormGroup className={classes.editor}>
                    <FormGroup>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label=""
                            value={TitleMail}
                            onChange={e => {
                                this.setState({TitleMail: _.get(e, "target.value", "")})
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment
                                    position="start">{Config.lang("Tieu_de")}</InputAdornment>,
                            }}
                        />
                    </FormGroup>
                    <Editor
                        value={Content}
                        onEditorChange={e => this.onEditorChange(e, "Content")}
                        init={{
                            content_style: ".mce-content-body > p > span {font-size:16px}",
                            height: 500,
                            menubar: false,
                            statusbar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | fontselect | fontsizeselect | bold italic backcolor ' +
                                'alignleft aligncenter alignright alignjustify' +
                                'bullist numlist outdent indent | removeformat',
                            toolbar_location: 'bottom',
                            fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
                        }}
                    />
                </FormGroup>
                <div className={classes.flexDRow}>
                    <ButtonGeneral
                        name={"Send"}
                        typeButton={"send"}
                        disabled={disableBtnSend || isSending || isEmployee || (ApproveLevel > 1 && ApprovalStatus === 0) || isViewOnly}
                        color={"primary"}
                        variant={"contained"}
                        loading={isSending}
                        className={"mgr5"}
                        size={"large"}
                        onClick={onSendMail}/>
                </div>
            </>
        )
    }

    render() {
        const {classes, getMailTemplate} = this.props;
        const {templateMailID, isLoadingDataMail, isLoadingMailTemplate, isSending} = this.state;
        return (
            <Accordion defaultExpanded className={classes.boxShadow}>
                <AccordionSummary
                    classes={{
                        root: classes.expansionSummaryRoot,
                        content: `${classes.expansionSummaryContent}  withSubTitle`,
                    }}
                    IconButtonProps={{size: "small"}}
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <Typography variant="h6">{Config.lang("Trao_doi_email")}</Typography>
                    <Typography className={classes.subText} variant="subtitle2">
                        {Config.lang("Trao_doi_gui_va_nhan_voi_ung_vien_ve_buoi_phong_van_nay")}
                    </Typography>
                </AccordionSummary>
                <Divider variant="middle"/>
                <AccordionDetails classes={{root: classes.expansionDetailsRoot}}>
                    <FormGroup>
                        <FormGroup>
                            <Combo
                                loading={isLoadingMailTemplate}
                                showClearButton={true}
                                disabled={isSending}
                                label={Config.lang("Chon_mau_mail_co_san")}
                                dataSource={getMailTemplate}
                                displayExpr={"TemplateMailName"}
                                valueExpr={"TemplateMailID"}
                                shrink={false}
                                onValueChanged={this.onChangeTemplateMail}
                                value={templateMailID}
                                stylingMode={"underlined"}
                            />
                            {this.renderTemplateMail()}
                        </FormGroup>
                    </FormGroup>
                    <List disablePadding>
                        {
                            !isLoadingDataMail ? this.renderDataMail() :
                                <div className={classes.flexDRow} style={{justifyContent: "center"}}>
                                    <ReactLoading
                                        type={'spin'}
                                        height={60}
                                        width={60}
                                        className={classes.fillLoading}/>
                                </div>
                        }
                    </List>
                </AccordionDetails>
            </Accordion>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dataForm.CandidateID !== this.props.dataForm.CandidateID ||
            prevProps.dataForm.RecInfoID !== this.props.dataForm.RecInfoID ||
            prevProps.dataForm.InterviewFileID !== this.props.dataForm.InterviewFileID ||
            prevProps.reloadDataMail !== this.props.reloadDataMail
        ) {
            this.loadMailData();
        }
    }

    componentDidMount = () => {
        this.loadMailData();
        this.loadMailTemplate()
    }
}

CandidateMailExchangeCard.propTypes = {
    dataForm: PropTypes.object.isRequired,
    sendNewMail: PropTypes.bool,
};

CandidateMailExchangeCard.defaultProps = {
    sendNewMail: false,
    isViewOnly: false,
};

export default compose(
    connect(
        (state) => ({
            getMailTemplate: state.W25F2033.getMailTemplate,
            loadMailData: state.W25F2033.loadMailData,
            loadInfoCandidate: state.W25F2012.loadInfoCandidate,
        }),
        (dispatch) => ({
            w25f2033Actions: bindActionCreators(W25F2033Actions, dispatch),
        }),
    ),
    withStyles(styles, {withTheme: true}),
)(CandidateMailExchangeCard);

