/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/29/2020
 * @Example 
 */

import { withStyles } from "@material-ui/core";
import { Accordion, AccordionDetails, AccordionSummary, TextInput, Typography } from 'diginet-core-ui/components';
import Icon from 'diginet-core-ui/icons';
import * as _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import Config from "../../../../config";

const styles = {
    ExpansionPanelSummary: {
        margin: 0,
        minHeight: 0,
        "&.Mui-expanded": {
            minHeight: 0
        },
        "& .MuiExpansionPanelSummary-content": {
            margin: 0,
            "&.Mui-expanded": {
                margin: 0
            }
        }
    },
};

class W25F2001General extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
    }

    render() {
        const { classes, data } = this.props;
        const { PlanName = "", DepartmentName = "", TeamName = "", ProjectName = "", Description = "", VoucherDate = "", RecPositionName = "",
            ReasonRequest = "", WorkName = "", WorkPlace = "", AgeFrom = "", AgeTo = "", Personality = "", ProNote = "" } = data;
        const salaryValue = _.get(data, "SalaryFrom", "") + " - " + _.get(data, "SalaryTo", "") + " " + _.get(data, "CurrencyName", "") + "/" + _.get(data, "SalUnitName", "");
        const { expanded } = this.state;
        const ProNumber = _.get(data, "ProNumber", 0) + " ("
            + _.get(data, "MaleQuan", 0) + " " + Config.lang("Nam1") + ", "
            + _.get(data, "FemaleQuan", 0) + " " + Config.lang("Nu") + ") "
            + _.get(data, "StatusQtyName", "");

        let TimeNeed = _.get(data, "DateFrom", "") ? Config.convertDate(_.get(data, "DateFrom", ""), "") : "";
        TimeNeed = TimeNeed && _.get(data, "DateTo", "") ? TimeNeed + " - " + Config.convertDate(_.get(data, "DateTo", ""), "") : "";

        return (
            <div style={{ width: '100%' }}>
                <FormGroup className={"mgb0"}>
                    <Row>
                        {!_.isEmpty(PlanName) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Ke_hoach_tong_the")}
                                    value={PlanName}
                                />
                            </Col>
                        }
                        {!_.isEmpty(DepartmentName) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Phong_ban")}
                                    value={DepartmentName}
                                />
                            </Col>
                        }
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        {!_.isEmpty(TeamName) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("To_nhom")}
                                    value={TeamName}
                                />
                            </Col>
                        }
                        {!_.isEmpty(ProjectName) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Du_an")}
                                    value={ProjectName}
                                />
                            </Col>
                        }
                    </Row>
                </FormGroup>
                {!_.isEmpty(Description) &&
                    <FormGroup>
                        <TextInput
                            readOnly={true}
                            multiline={true}
                            label={Config.lang("Mo_ta_cong_viec")}
                            value={Description}
                        />
                    </FormGroup>}
                <FormGroup className={"mgb0"}>
                    <Row>
                        {!_.isEmpty(RecPositionName) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Vi_tri_tuyen_dung")}
                                    value={RecPositionName}
                                />
                            </Col>
                        }
                        {!_.isEmpty(ProNumber) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    style={{ textTransform: "lowercase" }}
                                    label={Config.lang("So_luong")}
                                    value={ProNumber}
                                />
                            </Col>
                        }
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        {!_.isEmpty(VoucherDate) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Ngay_de_xuat")}
                                    value={VoucherDate ? Config.convertDate(_.get(data, "VoucherDate", ""), "") : ""}
                                />
                            </Col>
                        }
                        {!_.isEmpty(TimeNeed) &&
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Thoi_gian_can")}
                                    value={TimeNeed}
                                />
                            </Col>
                        }
                    </Row>
                </FormGroup>
                {!_.isEmpty(salaryValue) &&
                    <FormGroup className={"mgb0"}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Muc_luong")}
                                    value={salaryValue}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                }
                {!_.isEmpty(ReasonRequest) &&
                    <FormGroup className={"mgb0"}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    readOnly={true}
                                    label={Config.lang("Ly_do_tuyen")}
                                    value={ReasonRequest}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                }
                <FormGroup className={"mgb0"}>
                    <Accordion expand={expanded}>
                        <AccordionSummary style={{ display: 'none' }} >
                        </AccordionSummary>
                        <AccordionDetails style={{ margin: 0, paddingTop: 0 }}>
                            <div style={{ width: "100%" }}>
                                {!_.isEmpty(WorkName) &&
                                    <FormGroup className={"mgb0"}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly={true}
                                                    label={Config.lang("Cong_viec")}
                                                    value={WorkName}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                }
                                {!_.isEmpty(WorkPlace) &&
                                    <FormGroup className={"mgb0"}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly={true}
                                                    label={Config.lang("Dia_diem")}
                                                    value={WorkPlace}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                }
                                {(!_.isEmpty(AgeFrom) || !_.isEmpty(AgeTo)) &&
                                    <FormGroup className={"mgb0"}>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <TextInput
                                                    readOnly={true}
                                                    label={Config.lang("Do_tuoi")}
                                                    value={AgeFrom + " - " + AgeTo}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                }
                                {!_.isEmpty(Personality) &&
                                    <FormGroup className={"mgb0"}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly={true}
                                                    label={Config.lang("Tinh_cach")}
                                                    value={Personality}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                }
                                {!_.isEmpty(ProNote) &&
                                    <FormGroup className={"mgb0"}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly={true}
                                                    label={Config.lang("Ghi_chu")}
                                                    value={ProNote}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div className={"display_col align-center valign-middle"} >
                        <span style={{ cursor: "pointer", textAlign: 'center' }} onClick={() => this.setState({ expanded: !expanded })}>
                            <Icon
                                height={32}
                                width={32}
                                color={"primary"}
                                style={{ cursor: "pointer", padding: 0 }}
                                name={expanded ? "ArrowDoubleUp" : "ArrowDoubleDown"} />
                            <Typography className={classes.heading}>{expanded ? Config.lang("Thu_gon") : Config.lang("Mo_rong")}</Typography>
                        </span>
                    </div>
                </FormGroup>
            </div>
        );
    }

}

W25F2001General.propTypes = {
    data: PropTypes.object
};

export default withStyles(styles)(W25F2001General);
