/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/29/2020
 * @Example 
 */
import { FormControl } from "@material-ui/core";
import { Dropdown, Label, NumberInput, TextInput } from 'diginet-core-ui/components';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Config from "../../../../config";
class W25F2002EmployeeInfo extends Component {
    render() {
        const { onChange, error, formData, getCboWorks, disabled, cboWorkLoading } = this.props;
        const { MaleQuan, FemaleQuan, WorkID, WorkPlace, AgeFrom, AgeTo, Personality, ProNoteU } = formData;
        return (
            <div style={{ width: "100%" }}>
                <Row>
                    <Col xs={12} sm={3} md={3} lg={3}>
                        <NumberInput
                            min={0}
                            label={Config.lang("Sl_nam")}
                            onChange={(e) => { if (onChange) onChange("MaleQuan", e) }}
                            disabled={disabled}
                            value={MaleQuan}
                        />
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3}>
                        <NumberInput
                            min={0}
                            label={Config.lang("Sl_nu")}
                            onChange={(e) => { if (onChange) onChange("FemaleQuan", e) }}
                            disabled={disabled}
                            value={FemaleQuan}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <Dropdown
                            clearAble
                            valueExpr={"WorkID"}
                            keyExpr={"WorkName"}
                            displayExpr={"WorkName"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("Cong_viec")}
                            onChange={e => { if (onChange) onChange("WorkID", e) }}
                            disabled={disabled}
                            dataSource={getCboWorks}
                            loading={cboWorkLoading}
                            value={WorkID}
                        />
                    </Col>
                </Row>

                <Row>

                    <Col xs={12} sm={6} md={6} lg={6}>
                        <FormControl
                            className={"mgt5"}
                            variant={"standard"}
                            fullWidth={true}>
                            <Label style={{ margin: 0 }} >{Config.lang("Do_tuoi")}</Label>
                            <Row className={"mgt5"}>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <NumberInput
                                        min={0}
                                        onChange={(e) => { if (onChange) onChange("AgeFrom", e) }}
                                        disabled={disabled}
                                        value={AgeFrom}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <NumberInput
                                        min={0}
                                        error={error && error["AgeTo"]}
                                        onChange={(e) => { if (onChange) onChange("AgeTo", e) }}
                                        disabled={disabled}
                                        value={AgeTo}
                                    />
                                </Col>
                            </Row>
                        </FormControl>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6}>
                        <TextInput
                            multiline={true}
                            label={Config.lang("Dia_diem")}
                            onChange={(e) => { if (onChange) onChange("WorkPlace", e) }}
                            disabled={disabled}
                            value={WorkPlace}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            multiline={true}
                            label={Config.lang("Tinh_cach")}
                            onChange={(e) => { if (onChange) onChange("Personality", e) }}
                            disabled={disabled}
                            value={Personality}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            multiline={true}
                            label={Config.lang("Ghi_chu")}
                            onChange={(e) => { if (onChange) onChange("ProNoteU", e) }}
                            disabled={disabled}
                            value={ProNoteU}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

W25F2002EmployeeInfo.propTypes = {
    disabled: PropTypes.bool,
    cboWorkLoading: PropTypes.bool,
    error: PropTypes.object,
    formData: PropTypes.object,
    getCboWorks: PropTypes.array,
    onChange: PropTypes.func,
};

export default W25F2002EmployeeInfo;